import { Component, OnInit, Injectable, OnDestroy, Input, HostListener } from '@angular/core';

import { APP_ENABLED, LocalStorageData } from '../pagedata';
import { LayoutData } from '../interfaces/layoutData.interface';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { initLayoutData, initSalonData } from '../initData/initData';
import { PagedataService } from '../services/pagedata.service';
import { Router } from '@angular/router';
import { ReservationServiceProvider } from '../services/ReservationServiceProvider';
import { RequestParamsService } from '../services/requestParams.service';
import { HomeToMobilefooterService } from '../services/homeToMobilefooter';
import { NavigationController } from '../services/navigationController';
import { Subscription } from 'rxjs';
import { MyPageService } from '../services/myPage.service';
import { ShopListMapModalService } from '../services/shopListMapModal.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertController, ModalController } from '@ionic/angular';
import { MessageComponent } from '../message/message.component';
import { UpdateGoogleAnalyticsService } from '../services/updateGoogleAnalyticsService';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NoticeCloseA2hs } from '../services/noticeCloseA2hs';

@Component({
  selector: 'app-mobilefooter',
  templateUrl: './mobilefooter.component.html',
  styleUrls: ['./mobilefooter.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class MobilefooterComponent implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() pageName: string;

  // ぼかしクラスの制御
  @Input() blurActiveFlg: boolean = false;

  showInstallMessage: boolean = false;
  showShopListMobileBtn: boolean = false;
  showAccessMobileBtn: boolean = false;
  showShopListStandaloneBtn: boolean = false;
  isShowClassAccessMobileBtn: boolean = true; // アクセスボタン表示切り替えクラス
  private subscription: Subscription;
  private subscriptionForA2hs: Subscription;

  /* マイページ用フッターorブラウザ用フッター */
  showMobileActionApp: boolean = false;
  showMobileActionBrowser: boolean = false;

  layoutData: LayoutData = initLayoutData;
  companyLayoutData: LayoutData = initLayoutData;
  isDeprecationMessage: boolean = false;
  public unreadCount: number = 0; // 未読メッセージ数
  samsungbrowserVer: string = '';
  isSIBupdateMessage: boolean = false; // SamsungInternetBrowserアップデートアラート（バージョン12以下でtrue）
  salonData: any;
  // 一度でもiPhone用のホーム画面に追加の「閉じる」をクリックしたかどうか。クリックしていたら、エンタープライズ（店舗詳細）を開いても出さない
  isClickedCloseForA2hs: boolean = false;
  deferredPrompt: any;
  isShowA2HS: boolean = false;
  a2HSicon: string = '';
  a2HSappName: string = '';

  constructor(
    public pds: PagedataService,
    public router: Router,
    public rs: ReservationServiceProvider,
    private navigationController: NavigationController,
    private homeToMobilefooterService: HomeToMobilefooterService,
    private requestParamsService: RequestParamsService,
    public myPageService: MyPageService,
    public shopListMapModalService: ShopListMapModalService,
    private clipboard: Clipboard,
    private alertController: AlertController,
    private modalController: ModalController,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    private noticeCloseA2hs: NoticeCloseA2hs
  ) {}

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    if (this.pds.getIsAndroid() && !this.pds.getIsDeprecation() && !this.pds.getIsOldsamsungbrowser()) {
      this.isShowA2HS = true;
    }
  }

  addToHomeScreen() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        // ホームに追加を決定した時点でインストールメッセージを消す
        this.closeInstallMessage();
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  ngOnInit() {
    // Detects if device is in standalone mode
    const isInStandaloneMode = this.pds.getIsStandAloneMode();
    // アプリ・ブラウザでフッターを出しわけ
    if (isInStandaloneMode && this.pds.viewMode !== 'browser') {
      this.showMobileActionApp = true;
    } else {
      this.showMobileActionBrowser = true;
    }

    // 未読メッセージの更新を検知
    this.myPageService.badgeState.subscribe((result: number) => {
      this.unreadCount = result;
      // アプリアイコンのバッジ更新
      // @note Androidでは未サポート https://developer.chrome.com/articles/badging-api/
      if (navigator.setAppBadge) {
        navigator.setAppBadge(this.unreadCount).catch((error) => {
          console.error(error);
        });
      }
    });

    // 非推奨ブラウザメッセージ
    if (this.pds.getIsAndroid() && this.pds.getIsDeprecation()) {
      this.isDeprecationMessage = true;
    }
    // SamsungInternetBrowserアップデートメッセージ
    if (this.pds.getIsAndroid() && this.pds.getIsOldsamsungbrowser()) {
      this.isSIBupdateMessage = true;
    }

    this.load();

    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.homeToMobilefooterService.sharedDataSource$.subscribe((msg) => {
      this.showInstallMessage = msg;
      this.subscription.unsubscribe();
    });
    this.subscriptionForA2hs = this.noticeCloseA2hs.sharedDataSource$.subscribe((isClose) => {
      if (isClose) {
        // iPhone・Android共通変数
        this.isClickedCloseForA2hs = true;
        // iPhone
        this.showInstallMessage = false;
        // Android
        this.isShowA2HS = false;
        this.subscriptionForA2hs.unsubscribe();
      }
    });
    this.isClickedCloseForA2hs = this.noticeCloseA2hs.getIsClose();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionForA2hs) {
      this.subscriptionForA2hs.unsubscribe();
    }
  }

  private async load() {
    this.layoutData = await this.pds.getLayoutData(this.shopName);
    const messageCount = await this.pds.getDataPromise(LocalStorageData.MESSAGE_COUNT);
    this.salonData = await this.pds.getSalonData(this.shopName);

    this.unreadCount = messageCount != null ? messageCount.unreadCount : 0;

    // Detects if page is shop
    const isShop = () => {
      return this.router.url.split('?')[0] == '/shop';
    };

    if (this.isShopDetailNav) {
      // 店舗詳細の場合はアクセスボタンを表示する
      this.showAccessMobileBtn = true;
      this.refreshFooterBtn(this.pageName);
      this.companyLayoutData = await this.pds.getLayoutData();
      // android用「ホーム画面に追加」メッセージのアイコン・アプリ名を更新
      this.a2HSicon = this.companyLayoutData.icon180;
      this.a2HSappName = this.companyLayoutData.appName;
    } else {
      // android用「ホーム画面に追加」メッセージのアイコン・アプリ名を更新
      this.a2HSicon = this.layoutData.icon180;
      this.a2HSappName = this.layoutData.appName;
      // Checks if should display btn_shopListMobileBottom
      // 条件１：モバイルフッターが表示されているとき
      // 条件２：本部（app_enabled=5）のとき
      // 条件3：アドバンス（app_enabled=4）かつ本部があるとき
      if (this.showMobileActionBrowser) {
        if (
          this.salonData['appEnabled'] == APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY ||
          (this.salonData['appEnabled'] == APP_ENABLED.PWA_APP_ADVANCE && this.salonData['companyId'])
        ) {
          this.showShopListMobileBtn = true;
        }
      }
      // Checks if should display btn_accessMobileBottom
      if (this.showMobileActionBrowser && this.layoutData.isDisplayShop && !isShop() && !this.showShopListMobileBtn) {
        this.showAccessMobileBtn = true;
      }

      // アプリ化した状態で、店舗詳細モーダルを開いていない場合
      if (this.showMobileActionApp && !this.isShopDetailNav) {
        // 本部orアドバンス（本部あり）なら店舗一覧ボタンを表示
        if (
          this.pds.getIsCompany() ||
          (this.salonData['appEnabled'] == APP_ENABLED.PWA_APP_ADVANCE && this.salonData['companyId'])
        ) {
          this.showShopListStandaloneBtn = true;
        }
      }
    }
  }

  refreshFooterBtn(pageName) {
    if (this.showMobileActionBrowser && this.layoutData.isDisplayShop && pageName != 'shop') {
      this.isShowClassAccessMobileBtn = true;
    } else {
      this.isShowClassAccessMobileBtn = false;
    }
  }

  // 付加情報のないパラメータ
  private defaultParamForReservation = () => {
    const param: ParamForReservation = {
      staffId: -1,
      lastReservation: -1,
      salonName: this.isShopDetailNav ? this.shopName : ''
    };
    return param;
  };

  public clickBooking() {
    // エンタープライズはmap画面に飛ばす
    let pageName = '';
    if (this.pds.getIsCompany()) {
      // 本部の場合
      pageName = this.isShopDetailNav ? MyPageService.RESERVATION : MyPageService.MAP;
    } else {
      // 本部以外の場合（通常のアドバンス、ベーシックの場合）
      pageName = MyPageService.RESERVATION;
    }
    this.myPageService.authenticateKey(pageName, this.defaultParamForReservation());
  }

  public async clickReservationHistory() {
    this.myPageService.authenticateKey(MyPageService.RESERVATION_LIST, this.defaultParamForReservation());
  }

  public async clickMessage() {
    this.myPageService.authenticateKey(MyPageService.MESSAGE_LIST, this.defaultParamForReservation());
  }

  public async clickShareImageList() {
    this.myPageService.authenticateKey(MyPageService.SHARED_IMAGES, this.defaultParamForReservation());
  }

  closeInstallMessage() {
    this.noticeCloseA2hs.onNotifySharedDataChanged(true);
  }

  closeDeprecationMessage() {
    this.isDeprecationMessage = false;
  }

  closeSIBupdateMessage() {
    this.isSIBupdateMessage = false;
  }

  async copyUrl() {
    this.clipboard.copy(window.location.href);
    const alert = await this.alertController.create({
      message: 'URLをコピーしました',
      buttons: ['OK']
    });
    await alert.present();
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 店舗一覧・地図モーダルを開く
  openShopListMapModal() {
    this.shopListMapModalService.openShopListMapModal();
  }
}
