import { Component, OnInit, Input } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';
import { ReservationServiceProvider } from '../services/ReservationServiceProvider';
import { RequestParamsService } from '../services/requestParams.service';
import { PagedataService } from '../services/pagedata.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageData } from '../pagedata';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { NotificationsService } from '../services/notifications.service';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-handshake',
  templateUrl: './handshake.component.html',
  styleUrls: ['./handshake.component.scss']
})
export class HandShakeComponent implements OnInit {
  // 電子レシートを利用しているか
  @Input() viewReceiptReservationApps: boolean;
  // サロンアカウント
  public salonAccountName: string = null;
  // サロン名
  public salonName: string = '';
  // iframeで表示するハンドシェイクのurl
  public handshakeUrl: string = null;
  // 認証番号
  public customerNumber: string = '----';
  // 認証済みフラグ
  public numberRegistered: boolean = false;
  // 認証エラー
  public numberError: boolean = false;
  // プログレス
  public searching: boolean = false;
  // オリジナルウェブアプリ顧客トークン
  private token: string = null;
  // LINEで通知を受け取るボタン、エリア
  public isLineGuidance = false;
  // ハンドシェイク完了メッセージ
  public completionMessage = '';

  constructor(
    private modalCtrl: ModalController,
    public rs: ReservationServiceProvider,
    private requestParamsService: RequestParamsService,
    public pds: PagedataService,
    private sanitizer: DomSanitizer,
    private notificationsService: NotificationsService,
    public accountService: AccountService
  ) {}

  ngOnInit() {
    this.load();
  }

  private async load() {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const salon = await this.pds.getData(LocalStorageData.SALON);
    this.salonName = salon != null ? salon.name : '';
    this.token = userData != null ? userData.token : null;
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.salonAccountName = accountData.accountName != null ? accountData.accountName : null;
    this.findCustomerNumber();
  }

  // モーダルを閉じる
  public async close() {
    await this.modalCtrl.dismiss();
  }

  // 認証番号を取得
  public async findCustomerNumber() {
    this.searching = true;
    this.customerNumber = '----';
    this.numberError = false;
    const result = await this.rs.registerCustomerUniqueNumber(this.token, this.salonAccountName).catch((error) => {
      // 失敗
      return '----';
    });
    // 認証番号表示
    if (result) {
      this.searching = false;
      this.customerNumber = result;
    }
  }

  // 完了したかチェック
  public async checkStatus() {
    this.searching = true;
    this.numberRegistered = false;
    dayjs.extend(utc);
    const salons = await this.rs.findSalonList(this.token).catch((error) => {
      return;
    });
    this.searching = false;
    if (salons && salons.length) {
      const currentSalon = salons.find((salon) => salon.mailAccount === this.salonAccountName);
      if (currentSalon.appSalon.customerId > 0) {
        // 完了
        this.numberRegistered = true;

        // LINE連携の確認
        const status = await this.rs
          .findNotificationStatus(this.salonAccountName, this.token, this.pds.getIsCompany())
          .catch((error) => {
            return error;
          });
        const isSupported = await this.notificationsService.isSupportedNotification();

        if (status && status.isLineMessagingEnabled && status.lineDeliverStatus === 0 && !isSupported) {
          // LINE連携前でプッシュ通知が利用できないなら表示する
          this.isLineGuidance = true;
          this.completionMessage = 'ハンドシェイクは完了しました';
        } else {
          this.isLineGuidance = false;
          this.completionMessage = '完了しました';
        }
      } else if (dayjs().isAfter(currentSalon.appSalon.affiliateNumberExpiredAt)) {
        // 期限切れ
        this.findCustomerNumber();
      }
    }
  }

  /**
   * LINE連係のURLを発行して遷移。LINEアプリを開く
   */
  public async registerLineNotification() {
    const lineResult: any = await this.rs
      .registerOrUpdateLineNotification(this.salonAccountName, this.token, true, this.pds.getIsCompany())
      .catch((error) => {
        return error;
      });
    if (lineResult.error) {
      // line連携エラー
      console.error(lineResult.error);
    } else if (lineResult.lineRegistrationUrl) {
      // LINE連携をしたことない場合はLINE友だちURLを開く
      window.location.href = lineResult.lineRegistrationUrl;
    } else if (lineResult.lineDeliverStatus === 1) {
      // 何もしない
      this.isLineGuidance = false;
      this.completionMessage = 'LINE通知設定が完了しました';
    }
  }
}
