import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EmergencyNewsPage } from './emergencynews.page';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipesModule } from './../pipe/pipe.module';
import { BlockComponentModule } from './../block/block.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, LazyLoadImageModule, PipesModule, BlockComponentModule],
  declarations: [EmergencyNewsPage],
  exports: [EmergencyNewsPage]
})
export class EmergencyNewsPageModule {}
