<!-- SNSエリア -->
<section
  class="snsIcons sec"
  *ngIf="(layoutData.shareLinkFacebook || layoutData.shareLinkTwitter || layoutData.shareLinkInstagram) && isShowSNSbtn"
>
  <!-- 例：モノクロ -->
  <!-- <section class="snsIcons sec mono">...</section> -->
  <div class="snsIcons_container">
    <ul class="snsIcons__list">
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkInstagram">
        <a href="{{ layoutData.shareLinkInstagram }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="instagram_icon"
            class="snsIcons__img snsIcons__img__instagram"
            alt="instagram_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkInstagram}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-instagramWhite.svg" class="snsIcons__img snsIcons__img__instagramWhite"></a> -->
      </li>
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkTwitter">
        <a href="{{ layoutData.shareLinkTwitter }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="twitter_icon"
            class="snsIcons__img snsIcons__img__twitter"
            alt="twitter_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkTwitter}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-twitterWhite.svg" class="snsIcons__img snsIcons__img__twitterWhite"></a> -->
      </li>
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkFacebook">
        <a href="{{ layoutData.shareLinkFacebook }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="fb_icon"
            class="snsIcons__img snsIcons__img__fb"
            alt="fb_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkFacebook}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-fbWhite.svg" class="snsIcons__img snsIcons__img__fbWhite"></a> -->
      </li>
    </ul>
  </div>
  <!-- /.snsIcons_container -->
</section>
<!-- /.snsIcons -->

<footer class="footer">
  <div class="footer_container">
    <nav class="footer_nav" *ngIf="showFooter">
      <ul class="footer_nav__list">
        <ng-container *ngFor="let m of layoutData.menus">
          <ng-container *ngIf="m.isDisplayCheckAppEnabled">
            <li class="footer_nav__item" [class.__modalStyle__]="isShopDetailNav">
              <span
                *ngIf="m.templateType == nil"
                class="footer_nav__link jpfont"
                (click)="clickLink(m.name)"
                [class.__modalStyle__]="isShopDetailNav"
                >{{ m.title }}</span
              >
              <span
                *ngIf="m.templateType == 'simple'"
                class="footer_nav__link jpfont"
                (click)="clickLink(m.name, { pageName: m.name })"
                [class.__modalStyle__]="isShopDetailNav"
                >{{ m.title }}</span
              >
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
    <!-- /.footer_nav -->

    <div class="footer_copyright">
      <p
        *ngIf="layoutData.copyright && layoutData.copyright.trim().length > 0"
        class="footer_copyright__line jpfont"
        [class.__modalStyle__]="isShopDetailNav"
      >
        &copy;{{ layoutData.copyright }}
      </p>
    </div>
    <!-- /.footer_copyright -->
  </div>
  <!-- /.footer_container -->
</footer>

<!-- <section class="mobileAction" name="blur_aria_gallery"> -->
<!-- <div class="mobileAction_container"> -->
<!-- <a href="#" class="mobileAction__access btn_accessMobileBottom">Access</a> -->
<!-- <a href="#" class="mobileAction__book btn_bookMobileBottom">予約する</a> -->
<!-- </div> /.mobileAction_container -->
<!-- </section> /.mobileAction -->
