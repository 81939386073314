import { Component, OnInit, Input } from '@angular/core';

import { LayoutData } from '../interfaces/layoutData.interface';
import { initLayoutData } from '../initData/initData';
import { PagedataService } from '../services/pagedata.service';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NavigationController } from '../services/navigationController';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;

  loadingImage = './assets/images/icons/loading-dot.png';
  fb_icon = './assets/images/icons/icon-fb.png';
  twitter_icon = './assets/images/icons/icon-x.png';
  instagram_icon = './assets/images/icons/icon-instagram.png';

  // 非公開時、アプリ時はfalse
  @Input() showFooter: boolean = true;

  layoutData: LayoutData = initLayoutData;
  isShowSNSbtn: boolean = true;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    public router: Router,
    private shopDetailModalService: ShopDetailModalService
  ) {}

  ngOnInit() {
    this.load();

    // Detects if device is in standalone mode
    const isInStandaloneMode = this.pds.getIsStandAloneMode();
    // Checks if should display footer:
    if (isInStandaloneMode && this.pds.viewMode !== 'browser') {
      this.showFooter = false;
    }
  }

  private async load() {
    this.layoutData = await this.pds.getLayoutData(this.shopName);

    // Detects if page is StaffDetail
    const isStaffDetail = () => {
      return this.router.url.split('?')[0] == '/staffdetail';
    };

    // Checks if should display SNS
    if (isStaffDetail()) {
      this.isShowSNSbtn = false;
    }
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }
}
