<svg
  class="nav_mail_svg"
  [class.__modalStyle__]="isShopDetailNav"
  version="1.0"
  id="レイヤー_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="57px"
  height="36px"
  viewBox="0 0 57 36"
  enable-background="new 0 0 57 36"
  xml:space="preserve"
>
  <g class="nav_mail">
    <rect x="16.5" y="11.323" stroke-width="1.4412" stroke-miterlimit="10" width="24" height="19" />
    <polyline
      stroke-width="1.5225"
      stroke-miterlimit="10"
      points="36.495,16.216 28.5,23.323
                  20.505,16.216 	"
    />
  </g>

  <g class="nav_mail_active">
    <path
      d="M15.779,10.602v20.441h25.441V10.602H15.779z M28.5,24.341l-8.501-7.556l1.012-1.139l7.489,6.657
                l7.489-6.657l1.012,1.139L28.5,24.341z"
    />
  </g>
</svg>
