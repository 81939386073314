<svg
  class="nav_reservation_svg"
  [class.__modalStyle__]="isShopDetailNav"
  version="1.0"
  id="レイヤー_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="60px"
  height="60px"
  viewBox="0 0 57 57"
  enable-background="new 0 0 57 57"
  xml:space="preserve"
>
  <g class="nav_reservation">
    <path
      class="nav_reservation_1"
      d="M37.712,23.279c-2.592,0-4.701,2.109-4.701,4.701c0,2.593,2.109,4.702,4.701,4.702s4.701-2.109,4.701-4.702
             C42.413,25.389,40.304,23.279,37.712,23.279z M41.141,29.601H36.38V24.84h1.5v3.261h3.261V29.601z"
    />
    <polygon
      class="nav_reservation_1"
      points="39,13.089 34.5,13.089 34.5,14.589 32.5,14.589 32.5,13.089 24.5,13.089 24.5,14.589 22.5,14.589
             22.5,13.089 18,13.089 18,16.839 39,16.839 	"
    />
    <path
      class="nav_reservation_1"
      d="M28.5,0C12.76,0,0,12.76,0,28.5S12.76,57,28.5,57S57,44.24,57,28.5S44.24,0,28.5,0z M30.985,32.589H16.5
             v-14.25v-1.5v-5.25h6v-2h2v2h8v-2h2v2h6v5.25v1.5v2.563H39v-2.563H18v12.75h12.985V32.589z M26.711,23.993v-3.577h3.577v3.577
             H26.711z M30.288,25.434v3.577h-3.577v-3.577H30.288z M20.784,23.993v-3.577h3.577v3.577H20.784z M24.361,25.434v3.577h-3.577
             v-3.577H24.361z M37.712,34.183c-3.419,0-6.201-2.782-6.201-6.202c0-3.419,2.782-6.201,6.201-6.201s6.201,2.782,6.201,6.201
             C43.913,31.4,41.131,34.183,37.712,34.183z"
    />
    <text class="nav_reservation_2 text" x="15px" y="50px">予約</text>
  </g>

  <g class="nav_reservation_active">
    <g>
      <path
        class="nav_reservation_active_1"
        d="M28.5,1.505c14.885,0,26.995,12.11,26.995,26.995S43.385,55.495,28.5,55.495S1.505,43.385,1.505,28.5
                  S13.615,1.505,28.5,1.505 M28.5,0C12.76,0,0,12.76,0,28.5S12.76,57,28.5,57S57,44.24,57,28.5S44.24,0,28.5,0L28.5,0z"
      />
    </g>
    <polyline
      class="nav_reservation_active_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      points="30.986,31.839 17.25,31.839
                17.25,12.339 39.75,12.339 39.75,20.902 	"
    />
    <line
      class="nav_reservation_active_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      x1="16.5"
      y1="17.589"
      x2="40.5"
      y2="17.589"
    />
    <line
      class="nav_reservation_active_2"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="23.5"
      y1="9.589"
      x2="23.5"
      y2="11.589"
    />
    <line
      class="nav_reservation_active_2"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="23.5"
      y1="11.589"
      x2="23.5"
      y2="14.589"
    />
    <line
      class="nav_reservation_active_2"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="33.5"
      y1="9.589"
      x2="33.5"
      y2="11.589"
    />
    <line
      class="nav_reservation_active_2"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="33.5"
      y1="11.589"
      x2="33.5"
      y2="14.589"
    />
    <rect class="nav_reservation_active_1" x="20.784" y="20.416" width="3.577" height="3.577" />
    <rect class="nav_reservation_active_1" x="26.712" y="20.416" width="3.577" height="3.577" />
    <rect class="nav_reservation_active_1" x="20.784" y="25.434" width="3.577" height="3.577" />
    <rect class="nav_reservation_active_1" x="26.712" y="25.434" width="3.577" height="3.577" />
    <circle
      class="nav_reservation_active_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      cx="37.712"
      cy="27.981"
      r="5.451"
    />
    <polyline
      class="nav_reservation_active_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      points="41.14,28.85 37.13,28.85 37.13,24.84
                "
    />
    <text
      class="nav_reservation_active_1"
      x="15px"
      y="50px"
      style="font-size: 12px; font-family: 'Noto Sans JP' !important"
    >
      予約
    </text>
  </g>
</svg>
