import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { HomePageData } from '../interfaces/homePageData.interface';
type Gtag = typeof gtag;
type WindowWithGtag = Window & { gtag: Gtag };

@Injectable({
  providedIn: 'root'
})
export class MetaDataController {
  private windowWithGtag!: WindowWithGtag;
  static readonly STAGING_DOMAIN: string = '.1cs-staging.jp';
  static readonly PRODUCTION_DOMAIN: string = '.1cs.jp';
  static readonly COMPANY_DOMAIN: string = '.pwah';
  static readonly SHOP_DOMAIN: string = '.pwa';
  private gaTag: string = null;
  private gaTrackingGa4Tag: string = null;
  private baseUrl = '';

  constructor(private pageTitle: Title, private meta: Meta, @Inject(DOCUMENT) private doc) {
    this.windowWithGtag = window as WindowWithGtag;
  }

  public createOGImage(ogData: HomePageData) {
    // 画像はホームのメイン画像、なければホームのサロン情報、それもなければ未設定にする
    let ogImage = '';
    if (ogData !== null) {
      ogImage = ogData.heroImagePc;
      if (ogData.heroImagePc === null || ogData.heroImagePc === '' || ogData.isDisplayHeroImagePc === false) {
        ogImage = ogData.shopImage;
      }
      if (
        ogData.shopImage === null ||
        ogData.shopImage === '' ||
        ogData.isDisplayShopImage === false ||
        ogData.isDisplayShop === false
      ) {
        ogImage = '';
      }
    }
    return ogImage;
  }

  public updateOGFirst(
    env: string,
    accountName: string,
    ownDomain: string,
    path: string,
    title: string,
    description: string,
    ogData: HomePageData,
    isCompany: boolean
  ) {
    let url = 'https://';
    const isProduction = env === 'production';
    if (ownDomain != null) {
      this.baseUrl = url + ownDomain;
    } else {
      if (path.includes('shopDetail=') || isCompany) {
        this.baseUrl = url + accountName + MetaDataController.COMPANY_DOMAIN;
      } else {
        this.baseUrl = url + accountName + MetaDataController.SHOP_DOMAIN;
      }
      if (isProduction) {
        this.baseUrl += MetaDataController.PRODUCTION_DOMAIN;
      } else {
        this.baseUrl += MetaDataController.STAGING_DOMAIN;
      }
    }
    // パスが「/home」だった場合、編集画面の基本設定に合わせてhomeなしを正規URLとする
    if (path === '/home') {
      path = '';
    }
    url = this.baseUrl + path;
    const image = this.createOGImage(ogData);
    this.meta.updateTag({ name: 'og-url', content: url });
    this.meta.updateTag({ name: 'og-title', content: title });
    this.meta.updateTag({ name: 'og-site_name', content: title });
    this.meta.updateTag({ name: 'og-description', content: description });
    this.meta.updateTag({ name: 'og-image', content: image });
  }

  public updateOGImage(ogData: HomePageData) {
    const image = this.createOGImage(ogData);
    this.meta.updateTag({ name: 'og-image', content: image });
  }

  public updateOGTitleDescription(siteName: string, description: string, path: string, title: string = null) {
    // パスが「/home」だった場合、編集画面の基本設定に合わせてhomeなしを正規URLとする
    if (path === '/home') {
      path = '';
    }
    let url = this.baseUrl + path;
    this.meta.updateTag({ name: 'og-url', content: url });
    this.meta.updateTag({ name: 'og-site_name', content: siteName });
    this.meta.updateTag({ name: 'og-description', content: description });
    if (title !== null) {
      this.meta.updateTag({ name: 'og-title', content: title });
    } else {
      this.meta.updateTag({ name: 'og-title', content: siteName });
    }
  }

  public updateTitleDescription(title: string, description: string) {
    this.pageTitle.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
  }

  public refreshTitleDescription() {
    // ページ本体
    let ionPage = this.doc.getElementById('ion-page');
    // 店舗一覧モーダル
    let listMapModal = this.doc.getElementById('listMapModal');
    // 店舗詳細モーダル
    let shopDetailModal = this.doc.getElementById('shopDetailModal');

    let pageTitle = '';
    let pageDescription = '';

    if (shopDetailModal) {
      // shopDetailModalが取得できている場合
      // 店舗詳細モーダルを開いていると判断し、店舗詳細の最前面ページ内容でタイトルを更新する
      let nav = shopDetailModal.getElementsByTagName('ion-nav')[0];
      let pageList = nav.getElementsByClassName('ion-page');
      let activePage = pageList[pageList.length - 1];
      let activePageContent = activePage.getElementsByTagName('ion-content');
      pageTitle = activePageContent[0].dataset.page_title;
      pageDescription = activePageContent[0].dataset.page_description;
    } else if (listMapModal) {
      // shopDetailModalが取得できない時に、listMapModalが取得できている場合
      // 店舗一覧を開いていると判断し、店舗一覧用の内容でタイトルを更新する
      pageTitle = '店舗一覧';
      pageDescription = '';
    } else if (ionPage) {
      // shopDetailModal、listMapModalが取得できない時に、ionPageが取得できている場合
      // モーダルなしのページを表示していると判断し、開いているページの最前面のページの内容でタイトルを更新する
      let pageList = ionPage.getElementsByClassName('ion-page');
      let activePage = pageList[pageList.length - 1];
      let activePageContent = activePage.getElementsByTagName('ion-content');
      pageTitle = activePageContent[0].dataset.page_title;
      pageDescription = activePageContent[0].dataset.page_description;
    }
    this.updateTitleDescription(pageTitle, pageDescription);
  }

  public updateLinkForWebManifest(URL: any) {
    this.doc.getElementById('salon-manifest').setAttribute('href', URL);
  }

  public updateLinkForFavicon(URL: any, faviconType: string) {
    this.doc.getElementById('salon-faviconImg').setAttribute('href', URL);
    this.doc.getElementById('salon-faviconImg').setAttribute('type', faviconType);
    this.doc.getElementById('salon-faviconImg-shortcut').setAttribute('href', URL);
    this.doc.getElementById('salon-faviconImg-shortcut').setAttribute('type', faviconType);
  }

  public updateAppName(appName: string) {
    this.meta.updateTag({ name: 'apple-mobile-web-app-title', content: appName });
  }

  public updateIosIcon(URL: any) {
    this.doc.getElementById('apple_icon').setAttribute('href', URL);
  }

  public addGAtag(pageName: string, pagePath: string, gaTrackingGa4Tag: string) {
    var head = this.doc.getElementsByTagName('head')[0];
    // ここからgtag
    var gaIncludeTag = this.doc.createElement('script');
    gaIncludeTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingGa4Tag;
    head.appendChild(gaIncludeTag);
    var gaScriptTag = this.doc.createElement('script');
    var gaScript =
      "window.dataLayer = window.dataLayer || [];\n  function gtag(){window.dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', '" +
      gaTrackingGa4Tag +
      "', { send_page_view: false , linker: { 'domains': ['test.1cs.jp','test.su7.jp','1cs.jp','su7.jp']}});" +
      "\n  gtag('get', '" +
      gaTrackingGa4Tag +
      "', 'client_id', (client_id) => {" +
      "document.getElementById('gaClientId').value = client_id" +
      "});\n  gtag('get', '" +
      gaTrackingGa4Tag +
      "', 'session_id', (session_id) => {" +
      "document.getElementById('gaSessionId').value = session_id" +
      '});';
    gaScriptTag.textContent = gaScript;
    head.appendChild(gaScriptTag);
    this.gaTrackingGa4Tag = gaTrackingGa4Tag;
    this.windowWithGtag.gtag('event', 'page_view', {
      page_title: pageName,
      page_path: pagePath,
      page_location: window.location.href
    });
  }

  public addGTM(gtid: string) {
    var head = this.doc.getElementsByTagName('head')[0];
    var gtmScriptTag = this.doc.createElement('script');
    var gtmScript =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
      gtid +
      "');";
    gtmScriptTag.textContent = gtmScript;
    head.appendChild(gtmScriptTag);

    var body = this.doc.getElementsByTagName('body')[0];
    var gtmNoscriptTag = this.doc.createElement('noscript');
    var gtmNoscriptIframe = this.doc.createElement('iframe');
    gtmNoscriptIframe.src = 'https://www.googletagmanager.com/ns.html?id=' + gtid;
    gtmNoscriptIframe.style.height = '0';
    gtmNoscriptIframe.style.width = '0';
    gtmNoscriptIframe.style.display = 'none';
    gtmNoscriptIframe.style.visibility = 'hidden';
    gtmNoscriptTag.appendChild(gtmNoscriptIframe);
    body.appendChild(gtmNoscriptTag);
  }

  public updateThemeColor(color: string) {
    this.meta.updateTag({ name: 'theme-color', content: color });
  }

  public updateIosSplash(
    URL_640_1336: any,
    URL_750_1334: any,
    URL_1242_2208: any,
    URL_1125_2436: any,
    URL_828_1792: any,
    URL_1242_2688: any,
    URL_1536_2048: any,
    URL_1668_2224: any,
    URL_1668_2388: any,
    URL_2048_2732: any,
    URL_1170_2532: any,
    URL_1284_2778: any,
    URL_1536_2048_LAND: any,
    URL_1668_2224_LAND: any,
    URL_1668_2388_LAND: any,
    URL_2048_2732_LAND: any,
    URL_1488_2266: any,
    URL_1488_2266_LAND: any,
    URL_1179_2556: any,
    URL_1290_2796: any,
    URL_1206_2622: any,
    URL_1320_2868: any
  ) {
    // 9:16
    this.doc.getElementById('icon_1136x640').setAttribute('href', URL_640_1336);
    this.doc.getElementById('icon_640x1136').setAttribute('href', URL_640_1336);
    this.doc.getElementById('icon_1334x750').setAttribute('href', URL_750_1334);
    this.doc.getElementById('icon_750x1334').setAttribute('href', URL_750_1334);
    this.doc.getElementById('icon_2208x1242').setAttribute('href', URL_1242_2208);
    this.doc.getElementById('icon_1242x2208').setAttribute('href', URL_1242_2208);
    // 9:19.5
    this.doc.getElementById('icon_2436x1125').setAttribute('href', URL_1125_2436);
    this.doc.getElementById('icon_1125x2436').setAttribute('href', URL_1125_2436);
    this.doc.getElementById('icon_1792x828').setAttribute('href', URL_828_1792);
    this.doc.getElementById('icon_828x1792').setAttribute('href', URL_828_1792);
    this.doc.getElementById('icon_1242x2688').setAttribute('href', URL_1242_2688);
    this.doc.getElementById('icon_2688x1242').setAttribute('href', URL_1242_2688);
    this.doc.getElementById('icon_1170x2532').setAttribute('href', URL_1170_2532);
    this.doc.getElementById('icon_2532x1170').setAttribute('href', URL_1170_2532);
    this.doc.getElementById('icon_1284x2778').setAttribute('href', URL_1284_2778);
    this.doc.getElementById('icon_2778x1284').setAttribute('href', URL_1284_2778);
    this.doc.getElementById('icon_1179x2556').setAttribute('href', URL_1179_2556);
    this.doc.getElementById('icon_2556x1179').setAttribute('href', URL_1179_2556);
    this.doc.getElementById('icon_1290x2796').setAttribute('href', URL_1290_2796);
    this.doc.getElementById('icon_2796x1290').setAttribute('href', URL_1290_2796);
    this.doc.getElementById('icon_1206x2622').setAttribute('href', URL_1206_2622);
    this.doc.getElementById('icon_2622x1206').setAttribute('href', URL_1206_2622);
    this.doc.getElementById('icon_1320x2868').setAttribute('href', URL_1320_2868);
    this.doc.getElementById('icon_2868x1320').setAttribute('href', URL_1320_2868);
    // 3:4
    this.doc.getElementById('icon_1536x2048').setAttribute('href', URL_1536_2048);
    this.doc.getElementById('icon_2048x1536').setAttribute('href', URL_1536_2048_LAND);
    this.doc.getElementById('icon_1668x2224').setAttribute('href', URL_1668_2224);
    this.doc.getElementById('icon_2224x1668').setAttribute('href', URL_1668_2224_LAND);
    this.doc.getElementById('icon_1668x2388').setAttribute('href', URL_1668_2388);
    this.doc.getElementById('icon_2388x1668').setAttribute('href', URL_1668_2388_LAND);
    this.doc.getElementById('icon_2048x2732').setAttribute('href', URL_2048_2732);
    this.doc.getElementById('icon_2732x2048').setAttribute('href', URL_2048_2732_LAND);
    this.doc.getElementById('icon_1488x2266').setAttribute('href', URL_1488_2266);
    this.doc.getElementById('icon_2266x1488').setAttribute('href', URL_1488_2266_LAND);
  }

  public scrollTop(idName: string) {
    if (this.doc.getElementById(idName) != undefined) {
      this.doc.getElementById(idName).scrollTop = 0;
    }
  }

  public removeIframe(iframeParentClassName: string) {
    let listiFrames = this.doc.getElementsByClassName(iframeParentClassName);
    listiFrames = Array.from(listiFrames);
    for (var i = 0; i < listiFrames.length; i++) {
      while (listiFrames[i].firstChild) {
        listiFrames[i].removeChild(listiFrames[i].firstChild);
      }
    }
  }

  // ホームの個別クーポンリンク押下時にクーポンまでスクロールするための処理
  public scrollToEle(idName: string) {
    if (this.doc.getElementById(idName) != undefined) {
      let rect = this.doc.getElementById(idName).getBoundingClientRect();
      // ヘッダーの高さを考慮する
      let headerHight = this.doc.getElementById('header').clientHeight;
      let y = rect.top;
      return y - headerHight;
    } else {
      return 0;
    }
  }

  // 店舗一覧モーダル表示時にion-app要素に専用クラスを付与する
  public addClassIonAppForOpenModal() {
    this.doc.getElementById('ion-app').classList.add('body-offset-for-open-modal');
  }
  // 店舗一覧モーダルクローズ時にion-app要素の専用クラスを取り外す
  public removeClassIonAppForOpenModal() {
    this.doc.getElementById('ion-app').classList.remove('body-offset-for-open-modal');
  }

  public getBody() {
    return this.doc.getElementsByTagName('body');
  }

  public getGaTag() {
    return this.gaTag;
  }

  public getGaTrackingGa4Tag() {
    return this.gaTrackingGa4Tag;
  }

  public getGaClientId() {
    let gaClientId = this.doc.getElementById('gaClientId').value;
    if (gaClientId !== null && gaClientId.length > 0) {
      return gaClientId;
    } else {
      return null;
    }
  }

  public getGaSessionId() {
    let gaSessionId = this.doc.getElementById('gaSessionId').value;
    if (gaSessionId !== null && gaSessionId.length > 0) {
      return gaSessionId;
    } else {
      return null;
    }
  }

  // 独自ドメイン利用店舗のみカノニカルタグを作成or更新する
  public createOrUpdateCanonical(ownDomain: string, path: string) {
    let canonicalUrl = 'https://';
    canonicalUrl = canonicalUrl + ownDomain;
    // パスが「/home」だった場合、編集画面の基本設定に合わせてhomeなしを正規URLとする
    if (path == '/home') {
      path = '';
    }
    canonicalUrl = canonicalUrl + path;
    var element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null;
    if (element != null) {
      element.setAttribute('href', canonicalUrl);
    } else {
      const head = this.doc.getElementsByTagName('head')[0];
      const linkElement = this.doc.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      linkElement.setAttribute('href', canonicalUrl);
      head.appendChild(linkElement);
    }
  }

  // 構造化タグ（"@type": "BeautySalon"）を入れる
  public createBeautySalonStructuredData(salonName: string, address: string, phoneNumber: string, ownDomain: string) {
    // "@type": "BeautySalon" の構造化データを持つ script タグを探します
    const scriptElement = this.doc.querySelector('script[type="application/ld+json"]');

    // 既存の JSON-LD データを取得
    let existingData = {};
    if (scriptElement) {
      existingData = JSON.parse(scriptElement.innerText || '{}');
    }
    let url = this.doc.location.origin;
    if (ownDomain && ownDomain.trim() !== '') {
      url = 'https://' + ownDomain;
    }
    const jsonLd = this.createStructuredData(salonName, address, phoneNumber, url);

    if (existingData['@type'] !== 'BeautySalon') {
      const head = this.doc.getElementsByTagName('head')[0];
      let beautySalonStructuredData = this.doc.createElement('script');
      beautySalonStructuredData.setAttribute('type', 'application/ld+json');
      beautySalonStructuredData.innerText = JSON.stringify(jsonLd);
      head.appendChild(beautySalonStructuredData);
    }
  }

  // 構造化タグ（"@type": "BeautySalon"）を更新する
  public updateBeautySalonStructuredDataForShopDetail(
    salonName: string,
    address: string,
    phoneNumber: string,
    accountName: string
  ) {
    // "@type": "BeautySalon" の構造化データを持つ script タグを探します
    const scriptElement = this.doc.querySelector('script[type="application/ld+json"]');
    // 既存の JSON-LD データを取得
    let existingData = {};
    if (scriptElement) {
      existingData = JSON.parse(scriptElement.innerText || '{}');
    }
    if (existingData['@type'] === 'BeautySalon') {
      // JSON-LD の内容を更新します
      let url = this.doc.location.origin;
      if (this.baseUrl && this.baseUrl.trim() !== '') {
        url = this.baseUrl + '/home?shopDetail=' + accountName;
      }
      const jsonLd = this.createStructuredData(salonName, address, phoneNumber, url);
      // 既存の JSON-LD データを更新します
      scriptElement.innerText = JSON.stringify(jsonLd);
    }
  }

  public createStructuredData(salonName: string, address: string, phoneNumber: string, url: string) {
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'BeautySalon',
      name: salonName
    };

    if (address && address.trim() !== '') {
      jsonLd['address'] = address;
    }
    if (phoneNumber && phoneNumber.trim() !== '') {
      jsonLd['telephone'] = phoneNumber;
    }
    if (url && url.trim() !== '') {
      jsonLd['url'] = url;
    }
    return jsonLd;
  }

  // 構造化タグ（"@type": "WebSite"）を入れる
  public createWebSiteStructuredData(salonName: string, ownDomain: string) {
    // "@type": "BeautySalon" の構造化データを持つ script タグを探します
    const scriptElement = this.doc.querySelector('script[type="application/ld+json"]');

    // 既存の JSON-LD データを取得
    let existingData = {};
    if (scriptElement) {
      existingData = JSON.parse(scriptElement.innerText || '{}');
    }

    // JSON-LD の内容を更新します
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: salonName
    };

    if (ownDomain && ownDomain.trim() !== '') {
      jsonLd['url'] = 'https://' + ownDomain;
    } else {
      jsonLd['url'] = this.doc.location.origin;
    }

    // もし "@type": "BeautySalon" のデータが存在する場合、その内容を更新します
    if (existingData['@type'] === 'WebSite') {
    } else {
      // もし存在しない場合、新しい script タグを作成して追加します
      const head = this.doc.getElementsByTagName('head')[0];
      let beautySalonStructuredData = this.doc.createElement('script');
      beautySalonStructuredData.setAttribute('type', 'application/ld+json');
      beautySalonStructuredData.innerText = JSON.stringify(jsonLd);
      head.appendChild(beautySalonStructuredData);
    }
  }

  // 構造化タグ（"@type": "WebSite"）を消す（/、/home以外は消す）
  public deleteWebSiteStructuredData() {
    // 指定された構造化データを持つ script タグを探します
    const scriptElements = this.doc.querySelectorAll('script[type="application/ld+json"]');

    scriptElements.forEach((element) => {
      const data = JSON.parse(element.innerText || '{}');
      if (data['@type'] === 'WebSite') {
        // 該当する script タグを削除します
        element.remove();
      }
    });
  }

  // noindexタグを追加する
  public addNoIndex() {
    const head = this.doc.getElementsByTagName('head')[0];
    let noIndex = this.doc.createElement('meta');
    noIndex.setAttribute('name', 'robots');
    noIndex.setAttribute('content', 'noindex');
    head.appendChild(noIndex);
  }
}
