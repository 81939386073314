import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, Input } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { StaffDetailPageData } from '../interfaces/staffDetailPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { initLayoutData, initStaffdetailPage, initSalonData } from '../initData/initData';
import { RequestParamsService } from '../services/requestParams.service';
import { NavigationController } from '../services/navigationController';
import { MetaDataController } from '../services/metaDataController';
import { Router } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActive } from '../services/noticeBlurActive';
import { NoticeBlurActiveForModal } from '../services/noticeBlurActiveForModal';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { MyPageService } from '../services/myPage.service';
import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';

@Component({
  selector: 'app-staffdetail',
  templateUrl: './staffdetail.page.html',
  styleUrls: ['./staffdetail.page.scss']
})
export class StaffdetailPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('galleryModal', { static: false }) galleryModal;
  loadingImage = './assets/images/icons/loading-dot.png';
  twitter_icon = './assets/images/icons/icon-x.png';
  instagram_icon = './assets/images/icons/icon-instagram.png';
  layoutData: LayoutData = initLayoutData;

  pageData: StaffDetailPageData = initStaffdetailPage;
  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  // モーダルを開くでtrue、閉じるとfalse
  openModalFlg: boolean = false;
  // 他コンポーネントのぼかしクラスの制御
  blurActiveFlg: boolean = false;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  pageDataForPub: StaffDetailPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  isStaff: boolean = true;

  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private requestParamsService: RequestParamsService,
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private noticeBlurActive: NoticeBlurActive,
    private noticeBlurActiveForModal: NoticeBlurActiveForModal,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private myPageService: MyPageService,
    public router: Router
  ) {}

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.title != null) {
    } else {
      const promise = this.load();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
      if (this.openModalFlg) {
        this.galleryModal.closeModal('staffDetailModal__content');
      }
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidEnter() {
    if (this.openModalFlg) {
      this.addBlurActive();
    }
  }

  ionViewWillLeave() {
    this.removeBlurActive();
  }

  private async load() {
    let params = this.requestParamsService.getRequestParams();
    let staffId = params.staffId;
    if (staffId == null && this.isShopDetailNav) {
      staffId = this.requestParams.staff_id;
    }
    this.layoutData = await this.pds.getLayoutData(this.shopName);
    this.pageData = await this.pds.getStaffDetailPageData(this.shopName, staffId);
    this.salonData = await this.pds.getSalonData(this.shopName);
    this.isStaff = this.pageData.staffPerson.id !== null && typeof this.pageData.staffPerson.id !== 'undefined';
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (this.pds.getPreview()) {
      if (
        !this.pageData.isDisplay ||
        this.pageData.staffPersonPrev.id === null ||
        typeof this.pageData.staffPersonPrev.id === 'undefined'
      ) {
        this.isDisplayPrevPage = false;
        this.pageDataForPub = await this.pds.getStaffDetailPageData(this.shopName, staffId, false);
        if (
          !this.pageDataForPub.isDisplay ||
          this.pageDataForPub.staffPerson.id === null ||
          typeof this.pageDataForPub.staffPerson.id === 'undefined'
        ) {
          this.isDisplayPubPage = false;
        }
      }
    } else {
      if (!this.pageData.isDisplay || !this.isStaff) {
        if (this.isShopDetailNav) {
          this.shopDetailModalService.toHome(this.shopName);
        } else {
          this.navigationController.toHome();
        }
      }
    }
    //this.galleryImageHasNext = this.pageData['staffPerson']['hasNext'];
    //console.log('galleryImageHasNext:' + this.galleryImageHasNext);
    //this.galleryImageDataOffset += 9;
    //this.pageData.isDisplayVisual = true; // TODO
    console.log(this.pageData);

    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );
  }

  addBlurActive() {
    // ヘッダーにぼかしクラスをつける
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(true);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(true);
    }
    this.blurActiveFlg = true;
  }

  removeBlurActive() {
    // ヘッダーのぼかしクラスを外す
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(false);
    }
    this.blurActiveFlg = false;
  }

  modalcontrol(flg: boolean) {
    if (flg) {
      this.openModalFlg = true;
      this.addBlurActive();
    } else {
      this.openModalFlg = false;
      this.removeBlurActive();
    }
  }

  // 指名予約
  public async clickBooking(staffInfo: any) {
    const staffId: number = staffInfo.csStaffId != null ? staffInfo.csStaffId : -1;
    const param: ParamForReservation = {
      staffId,
      lastReservation: -1,
      salonName: this.shopName !== undefined ? this.shopName : null
    };
    this.myPageService.authenticateKey(MyPageService.RESERVATION, param);
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
