import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-line-notification-modal',
  templateUrl: './line-notification-modal.component.html',
  styleUrls: ['./line-notification-modal.component.scss']
})
export class LineNotificationModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public async next() {
    await this.modalCtrl.dismiss(true, 'selected');
  }
}
