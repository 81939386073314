import { Component, ChangeDetectorRef, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { createAnimation } from '@ionic/core';
import { MyPageService } from '../services/myPage.service';
import { PagedataService } from '../services/pagedata.service';
import { ReservationServiceProvider } from '../services/ReservationServiceProvider';
import { LocalStorageData } from '../pagedata';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';

@Component({
  selector: 'app-mobilefooterbuttons',
  templateUrl: './mobilefooterbuttons.component.html',
  styleUrls: ['./mobilefooterbuttons.component.scss']
})
export class MobilefooterbuttonsComponent implements OnInit {
  private isShowScrollTopForMobile = false;
  // フローティングボタン 非表示アニメーション中フラグ
  private floatingButtonHideAnimationFlag: boolean = false;
  @Output() scrollToTopEvent = new EventEmitter();
  private isShowLastReservation: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private elem: ElementRef,
    public myPageService: MyPageService,
    public pds: PagedataService,
    public rs: ReservationServiceProvider
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  scrollEvent(content: IonContent): any {
    content.getScrollElement().then((data) => {
      const scrollPos = data.scrollTop;
      // .scrollTopForMobileのshadowが切れないようにアイコンより高さ広め
      const scrollTopButtonHeight: string = '44px';
      if (scrollPos > 1200 && !this.isShowScrollTopForMobile) {
        //100pxスクロールしたら表示
        this.isShowScrollTopForMobile = true;
        this.changeDetector.detectChanges();
        createAnimation()
          .addElement(this.elem.nativeElement.querySelector('#scrollTopForMobileContainer'))
          .duration(300)
          .iterations(1)
          .fromTo('opacity', '0', '0.6')
          .fromTo('height', '0', scrollTopButtonHeight)
          .easing('ease-out')
          .play();
      } else if (scrollPos <= 1200 && this.isShowScrollTopForMobile && !this.floatingButtonHideAnimationFlag) {
        this.floatingButtonHideAnimationFlag = true;
        createAnimation()
          .addElement(this.elem.nativeElement.querySelector('#scrollTopForMobileContainer'))
          .duration(200)
          .iterations(1)
          .fromTo('opacity', '0.6', '0')
          .fromTo('height', scrollTopButtonHeight, '0')
          .easing('ease-in')
          .onFinish(() => {
            this.isShowScrollTopForMobile = false;
            this.floatingButtonHideAnimationFlag = false;
          })
          .play();
      }
    });
  }

  scrollToTop() {
    this.scrollToTopEvent.emit();
  }

  public async clickLastReservation() {
    // 前回予約パラメータを渡す
    const param: ParamForReservation = {
      staffId: -1,
      lastReservation: 1
    };
    this.myPageService.authenticateKey(MyPageService.RESERVATION, param);
  }

  public getIsShowScrollTopForMobile() {
    return this.isShowScrollTopForMobile;
  }

  public getIsShowLastReservation() {
    return this.isShowLastReservation;
  }
}
