import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { NewsPageData } from '../interfaces/newsPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initNewsPageData, initSalonData } from '../initData/initData';
import { NavigationController } from '../services/navigationController';
import { Router } from '@angular/router';

import { MetaDataController } from '../services/metaDataController';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

import { RequestParamsService } from '../services/requestParams.service';
import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.page.html',
  styleUrls: ['./news.page.scss']
})
export class NewsPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  pageData: NewsPageData = initNewsPageData;
  public blockData: any = [];

  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  public paramKey: string = null;
  pageDataForPub: NewsPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;

  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private requestParamsService: RequestParamsService,
    private elem: ElementRef,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    public router: Router
  ) {}

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 動画の読み直しのためsetBlockを実行する
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.title != null) {
    } else {
      const promise = this.load();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  private async load() {
    const params = this.requestParamsService.getRequestParams();
    this.paramKey = params.key;
    this.pageData = await this.pds.getNewsPageData(this.shopName);
    this.blockData = this.pageData.blockData;
    this.pds.remakeBlockData(this.blockData);
    this.salonData = await this.pds.getSalonData(this.shopName);
    if (!this.pageData.isDisplay && this.pds.getPreview()) {
      this.isDisplayPrevPage = false;
      this.pageDataForPub = await this.pds.getNewsPageData(this.shopName, false);
      if (!this.pageDataForPub.isDisplay) {
        this.isDisplayPubPage = false;
      }
    }
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (!this.pageData.isDisplay && !this.pds.getPreview()) {
      if (this.isShopDetailNav) {
        this.shopDetailModalService.toHome(this.shopName);
      } else {
        this.navigationController.toHome();
      }
    }
    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
