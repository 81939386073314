<div class="dialog">
  <div class="header-controls">
    <div class="buttons align-right">
      <ion-button fill="clear" size="small" (click)="close()">
        <ion-icon slot="icon-only" src="assets/images/icons/close-outline.svg" color="dark"> </ion-icon>
      </ion-button>
    </div>
  </div>
  <div class="container">
    <div class="dialog-title">ハンドシェイク</div>
    <div class="dialog-body">
      <ng-container *ngIf="!(numberRegistered || numberError)">
        <!-- ------------- ハンドシェイク ---------------- -->
        <div class="section-block">
          <div class="description color-red alignCenter">店内にいない場合は画面を閉じてください。</div>
        </div>
        <div class="section-title">{{ salonName }}</div>
        <div class="section-block quarter-padding">
          この番号をスタッフにご提示ください。 <br />
          ハンドシェイクをすると、アプリをより便利にご利用いただけます。
        </div>
        <div class="section-block quarter-padding">
          <ul class="custom-mark mark-double-circle bold">
            <li>アプリ初回予約時の連絡先の入力が不要</li>
            <li>予約履歴の閲覧</li>
            <li *ngIf="viewReceiptReservationApps">電子レシートの閲覧</li>
            <li>直前日にリマインド通知</li>
          </ul>
        </div>
        <!-- ハンドシェイク番号 -->
        <div class="section-block quarter-padding">
          <ion-button fill="clear" color="dark-button" class="dialog-button transparent" (click)="checkStatus()">
            <div class="handshake-number">{{ customerNumber }}</div>
          </ion-button>
          <ion-button fill="solid" color="light-button" class="dialog-button" (click)="checkStatus()">
            完了
          </ion-button>
        </div>
        <!-- スタッフ向け注釈 -->
        <div class="section-title">スタッフの方へ</div>
        アプリとお客様情報を紐づけるハンドシェイクを行ってください。 <br />
        予約管理画面で、お客様の予約情報からハンドシェイクの登録ができます。 <br />
        認証番号の有効期限は10分です。 <br />
        （再取得は更新マークをタップ）
      </ng-container>
      <!-- ------------- ハンドシェイク完了 ---------------- -->
      <ng-container *ngIf="numberRegistered">
        <!-- LINEガイドなし -->
        <div class="section-block align-center">{{ completionMessage }}</div>
        <!-- LINEガイドあり -->
        <ng-container *ngIf="isLineGuidance">
          <button class="lineNotify-button" (click)="registerLineNotification()">LINEで通知を受け取る</button>
          <div>
            予約に関するメッセージをLINEで受け取る場合はこのボタンをタップしてください。 <br />
            LINEが開きますので「追加」をタップして、通知設定用メッセージを送信してください。
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="progress-mask" *ngIf="searching">
    <ion-spinner name="lines"></ion-spinner>
  </div>
</div>
