import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MobilefooterComponent } from './mobilefooter.component';
import { PagedataService } from '../services/pagedata.service';
import { StoreListSvgComponent } from '../store-list-svg/store-list-svg.component';
import { ReservationListSvgComponent } from '../reservation-list-svg/reservation-list-svg.component';
import { SharedImageSvgComponent } from '../shared-image-svg/shared-image-svg.component';
import { MailSvgComponent } from '../mail-svg/mail-svg.component';
import { ReservationSvgComponent } from '../reservation-svg/reservation-svg.component';

@NgModule({
  declarations: [
    MobilefooterComponent,
    StoreListSvgComponent,
    ReservationListSvgComponent,
    SharedImageSvgComponent,
    MailSvgComponent,
    ReservationSvgComponent
  ],
  imports: [CommonModule, RouterModule, IonicModule],
  exports: [MobilefooterComponent]
})
export class MobilefooterModule {
  constructor(public pds: PagedataService) {}
}
