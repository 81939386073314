<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="shop_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [class.__modalStyle__]="isShopDetailNav"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
>
  <main class="main mainStoreDetail content_top rmMbLastContentsGroup">
    <!-- 店舗詳細見出し -->
    <section class="storeDetailHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="navigationController.setClickedRouterLink(true)"
              [class.__modalStyle__]="isShopDetailNav"
              >{{breadcrumbsData.title}}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span
          href="javascript:void(0);"
          class="storeDetailHeading__backLink"
          (click)="navigationController.goBack()"
          [class.__modalStyle__]="isShopDetailNav"
          ><</span
        >
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="storeDetailHeading_container secContainer">
        <h2 class="storeDetailHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">
          {{pageData.title}}
        </h2>
      </div>
      <div
        class="storeDetailBlock blockArea"
        *ngIf="blockData['storeDetail__titleBlock'] && blockData['storeDetail__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['storeDetail__titleBlock']"
          [position]="'storeDetail__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="storeDetailHeading_container secContainer">
        <p
          class="storeDetailHeading__desc"
          [innerHTML]="pageData.concept | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
      </div>
      <div
        class="storeDetailBlock blockArea"
        *ngIf="blockData['storeDetail__conceptBlock'] && blockData['storeDetail__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['storeDetail__conceptBlock']"
          [position]="'storeDetail__conceptBlock'"
          #appBlock
        ></app-block>
      </div>
      <hr class="storeDetailHeading__separateLine" [class.__modalStyle__]="isShopDetailNav" />
      <div class="storeDetailHeading_container secContainer">
        <h3 class="storeDetailHeading__storeName jpfont rmMbTarget" [class.__modalStyle__]="isShopDetailNav">
          {{pageData.name}}
        </h3>
      </div>
      <!-- /.storeDetailHeading_container -->
    </section>
    <!-- /.storeDetailHeading -->

    <section
      class="storeDetail__mainVisual secContent"
      *ngIf="(pageData.photo || pageData.photoIframe ) && pageData.isDisplayPhoto"
    >
      <div class="storeDetail__mainVisual__inner">
        <picture *ngIf="pageData.photoDisplayType != 4">
          <source *ngIf="pageData.webpPhoto != null" type="image/webp" [srcset]="pageData.webpPhoto" />
          <img
            class="storeDetail__mainVisual__inner__img"
            [src]="pageData.photo"
            alt="{{ pageData.photoAlt }}"
            [class.width90]="pageData.photoDisplayType == 2"
            [class.width50]="pageData.photoDisplayType == 3"
          />
        </picture>
        <div
          class="storeDetail__mainVisual__video iframe_shop"
          *ngIf="pageData.photoDisplayType == 4"
          [innerHTML]="photoHtml"
          #videoIframeContent
        ></div>
      </div>
      <div
        class="storeDetailBlock blockArea"
        *ngIf="blockData['storeDetail__visualBlock'] && blockData['storeDetail__visualBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['storeDetail__visualBlock']"
          [position]="'storeDetail__visualBlock'"
          #appBlock
        ></app-block>
      </div>
    </section>
    <!-- /.storeDetail__mainVisual -->
    <ng-container *ngIf="salonData.appEnabled != 5">
      <!-- 店舗詳細コンテンツ　サロン情報 -->
      <section class="storeDetailContent sec">
        <div class="storeDetailContent_container" *ngIf="salonData.appEnabled != 5">
          <div class="storeDetailContent__salon secContent">
            <table class="storeDetailContent__salon__infoTable">
              <tr
                *ngIf="pageData.tel && pageData.tel.trim().length > 0"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>Tel</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p><a [href]="'tel:' + pageData.tel">{{pageData.tel}}</a></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.businessHours"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>営業時間</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.businessHours"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.holiday"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>定休日</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.holiday"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.address && pageData.address.trim().length > 0"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>住所</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.address"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.access"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>アクセス</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.access"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.facilities"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>席数・設備</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.facilities"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.parkingLot"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>駐車場</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.parkingLot | htmlSanitize"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.creditCard"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>支払い方法</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.creditCard"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.others"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>備考</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.others"></p>
                </td>
              </tr>
              <tr
                *ngIf="pageData.recruit"
                class="storeDetailContent__salon__infoTable__tr"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <td class="storeDetailContent__salon__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
                  <p>スタッフ募集</p>
                </td>
                <td class="storeDetailContent__salon__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
                  <p [innerHTML]="pageData.recruit | htmlSanitize"></p>
                </td>
              </tr>
            </table>
          </div>
          <!-- /.storeDetailContent__salon -->
        </div>
        <!-- /.storeDetailContent_container -->
      </section>
      <!-- /.storeDetailContent -->

      <div
        class="storeDetailBlock blockArea"
        *ngIf="blockData['storeDetail__infoBlock'] && blockData['storeDetail__infoBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['storeDetail__infoBlock']"
          [position]="'storeDetail__infoBlock'"
          #appBlock
        ></app-block>
      </div>

      <!-- 店舗詳細コンテンツ　マップ -->
      <section class="storeDetailContent sec">
        <div
          class="storeDetailContent_container"
          *ngIf="pageData.address && pageData.address.trim().length > 0 && salonData.appEnabled != 5"
        >
          <!-- サロン情報 -->
          <div class="storeDetailContent__salon secContent">
            <div class="storeDetailContent__salon__gmap">
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.4886854567703!2d139.711713314567!3d35.66496733847246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b602a3f8dab%3A0xcf2d7367fdc7db54!2z5Y2X6Z2S5bGxIOe-juWuuemZoiDjg4njg4Pjg4jjgJBEb3QrTElN44CR576O5a655a6kLyBoYWlyIHNhbG9uIC8g44OY44Ki44K144Ot44Oz!5e0!3m2!1sja!2sjp!4v1551794716330" width="100%" frameborder="0" style="border:0" allowfullscreen></iframe> -->
              <iframe
                loading="lazy"
                id="shop_map"
                [src]="safeUrl"
                width="100%"
                frameborder="0"
                style="border: 0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- /.storeDetailContent__salon -->
        </div>
        <!-- /.storeDetailContent_container -->
      </section>
      <!-- /.storeDetailContent -->

      <div
        class="storeDetailBlock blockArea"
        *ngIf="blockData['storeDetail__mapBlock'] && blockData['storeDetail__mapBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['storeDetail__mapBlock']"
          [position]="'storeDetail__mapBlock'"
          #appBlock
        ></app-block>
      </div>
    </ng-container>
  </main>
  <!-- /.mainStoreDetail -->
  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="forShop"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
