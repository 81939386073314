import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-image-svg',
  templateUrl: './shared-image-svg.component.html',
  styleUrls: ['./shared-image-svg.component.scss']
})
export class SharedImageSvgComponent implements OnInit {
  @Input() isShopDetailNav: boolean = false;
  constructor() {}

  ngOnInit() {}
}
