import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-private',
  templateUrl: './private.page.html',
  styleUrls: ['./private.page.scss']
})
export class PrivatePage {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() privateMsgHtml: SafeHtml;
}
