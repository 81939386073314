import { Injectable } from '@angular/core';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { initDomainRelatedAccountData } from '../initData/initData';
import { RequestParamsService } from './requestParams.service';
import { PagedataApiService } from './pagedataApi.service';
import { environment } from '../../environments/environment';
import { APP_ENABLED } from '../pagedata';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private domainRelatedAccountData = initDomainRelatedAccountData;
  private isInitialized: boolean = false;

  constructor(private requestParamsService: RequestParamsService, private pagedataApiService: PagedataApiService) {}

  async fetchAndSetSalonFromOwnDomain() {
    if (!this.isInitialized) {
      const params = this.requestParamsService.getRequestParams();
      if (params.salonOrDomainName.includes('.')) {
        const ownDomainSalon = await this.pagedataApiService.getSalonFromOwnDomain(params.salonOrDomainName);
        this.domainRelatedAccountData.accountName = ownDomainSalon.salon.accountName;
        this.domainRelatedAccountData.isCompany =
          ownDomainSalon.salon.appEnabled === APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY;
        this.domainRelatedAccountData.isRedirect = false;
      } else {
        this.domainRelatedAccountData.accountName = params.salonOrDomainName;
        if (environment.local) {
          this.domainRelatedAccountData.isCompany = this.requestParamsService.isCompanyForLocal;
        } else {
          this.domainRelatedAccountData.isCompany = params.isCompanyForNonCustomDomain;
        }
        this.domainRelatedAccountData.isRedirect = true;
      }
      this.isInitialized = true;
    }
  }

  public getSalonNameAndIsCompany(): DomainRelatedAccountData {
    return this.domainRelatedAccountData;
  }
}
