import Dexie, { Table } from 'dexie';

export interface UserData {
  id: number;
  token: string;
}

/**
 * indexedDBを扱うサービス
 */
export class StorageService extends Dexie {
  userDatas: Table<UserData, number>;
  constructor() {
    super('_csPwa');
    this.version(1).stores({
      userDatas: 'id, token'
    });
  }

  public async setUserData(data: UserData): Promise<void> {
    // レコードは2つ以上になる予定がないのでidは0固定
    await db.userDatas.put({
      id: 0,
      token: data.token
    });
    return;
  }

  public async getUserData(): Promise<UserData> {
    const data = await db.userDatas.get(0);
    return data;
  }

  // db削除
  public deleteDb() {
    db.delete();
  }
}

export const db = new StorageService();
