import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { SalonPageData } from '../interfaces/salonPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initSalonPageData, initSalonData } from '../initData/initData';
import { NavigationController } from '../services/navigationController';
import { Router } from '@angular/router';

import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { MetaDataController } from '../services/metaDataController';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss']
})
export class ShopPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  safeUrl: SafeResourceUrl;
  url: string;

  photoHtml: SafeHtml;
  pageData: SalonPageData = initSalonPageData;
  public blockData: any = [];
  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  pageDataForPub: SalonPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;

  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private elem: ElementRef,
    public router: Router,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService
  ) {}

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 動画の読み直しのためsetBlockを実行する
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.title != null) {
    } else {
      const promise = this.load();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  private async load() {
    this.pageData = await this.pds.getSalonPageData(this.shopName);
    this.blockData = this.pageData.blockData;
    this.pds.remakeBlockData(this.blockData);
    this.salonData = await this.pds.getSalonData(this.shopName);

    if (!this.pageData.isDisplay && this.pds.getPreview()) {
      this.isDisplayPrevPage = false;
      this.pageDataForPub = await this.pds.getSalonPageData(this.shopName, false);
      if (!this.pageDataForPub.isDisplay) {
        this.isDisplayPubPage = false;
      }
    }
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }
    if (!this.pageData.isDisplay && !this.pds.getPreview()) {
      if (this.isShopDetailNav) {
        this.shopDetailModalService.toHome(this.shopName);
      } else {
        this.navigationController.toHome();
      }
    }

    const crArray = ['businessHours', 'holiday', 'access', 'facilities', 'creditCard', 'others', 'recruit'];

    for (var name of crArray) {
      this.pageData[name] = this.pageData[name].replace(/\n/g, '<br />');
    }

    this.url =
      'https://www.google.com/maps?q=' + this.pageData.lat + ',' + this.pageData.lon + '&output=embed&t=m&z=16';
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );

    if (this.pageData.photoDisplayType == 4) {
      this.photoHtml = this.sanitizer.bypassSecurityTrustHtml(this.pageData.photoIframe);
    }
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
