<div class="overflow_scroll" id="window_top">
  <main class="main mainPrivate content_top">
    <p class="mainContaints" [class.__modalStyle__]="isShopDetailNav" [innerHTML]="privateMsgHtml"></p>
  </main>
</div>
<app-footer
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [class.private]="true"
  [showFooter]="false"
></app-footer>
