export namespace LocalStorageData {
  export const APP_VER = 'appVer';
  export const IS_FIRST_START = 'isFirstStart';
  export const IS_SHOWN_OLD_ALERT = 'isShownOldOSAlert';
  export const USER_DATA = 'userData';
  export const MESSAGE_COUNT = 'messageCount';
  export const THEME = 'theme';
  export const LAYOUT = 'layout';
  export const HOME = 'home';
  export const MENU = 'menu';
  export const STAFF = 'staff';
  export const STAFFDETAIL = 'staffdetail';
  export const SALON = 'salon';
  export const GALLERY = 'gallery';
  export const CAMPAIGN = 'campaign';
  export const NEWS = 'news';
  export const NEWSDETAIL = 'newsdetail';
  export const FREE = 'free';
  export const SHOPLIST = 'shoplist';
  export const UPDATED_DATE = 'updatedDate';
  export const SALON_DATA = 'salonData';
  export const PUSH_DIALOG_DISPLAYED = 'pushDialogDisplayed';
  export const FAVORITE_SALON_ID_LIST = 'favorite_salon_id_list';
  export const INITIAL_APP_OPENED_SALON_TITLE = 'initialAppOpenedSalon';
  export const ADDED_FAVORITE_SALON = 'addedFavoriteSalon'; // アプリ立ち上げ時にCookieからお気に入り店舗を追加したかどうか
  // ローカルストレージに保存されている重要なデータ（データ溢れても消さない）
  export const IMPORTANT_DATA = [
    THEME,
    APP_VER,
    LAYOUT,
    UPDATED_DATE,
    HOME,
    SALON_DATA,
    IS_FIRST_START,
    IS_SHOWN_OLD_ALERT,
    USER_DATA,
    MESSAGE_COUNT,
    PUSH_DIALOG_DISPLAYED,
    FAVORITE_SALON_ID_LIST,
    INITIAL_APP_OPENED_SALON_TITLE,
    ADDED_FAVORITE_SALON
  ];
}

export const APP_ENABLED = {
  PWA_APP_DISABLED: 0,
  PWA_APP_APPLICABLE: 1,
  PWA_APP_IN_TRIAL: 2,
  PWA_APP_BASIC: 3,
  PWA_APP_ADVANCE: 4,
  PWA_APP_ENTERPRISE_COMPANY: 5,
  PWA_APP_ENTERPRISE_SHOP: 6,
  PWA_APP_ENTERPRISE_COMPANY_IN_INITIALIZE: 7,
  PWA_APP_ENTERPRISE_SHOP_IN_INITIALIZE: 8
} as const;

export const INSTANT_RESERVATION_BUTTON_NAME = {
  0: '全店<br>今日/明日予約',
  1: '今日・明日<br>空き検索',
  2: '今日・明日の<br>空席を探す',
  3: '全店<br>空き検索',
  4: '日時で<br>空席を探す',
  5: '日時で探す'
} as const;

export namespace CookieData {
  export const INITIAL_APP_OPENED_SALON_TITLE = 'initialAppOpenedSalon'; // この名前をキーにアプリ起動時に立ち上げる店舗アカウント名を保存
  export const VALID_DAYS = 36500; // 保存期限（100年）
}
