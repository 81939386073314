import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationController {
  private prevUrl: string;
  private currentUrl: string;
  // ページ内のリンクをクリックしてページ遷移したらtrue
  // 戻るボタンやスワイプでページを戻った場合はfalse
  private clickedRouterLink: boolean = false;
  public isOpenCardModal: boolean = false;

  constructor(private router: Router, private location: Location) {}

  public refreshUrl() {
    this.prevUrl = this.currentUrl;
    this.currentUrl = this.router.url;
  }

  public getPrevUrl() {
    return this.prevUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  public toRouterLink(url: string) {
    var path = url.split('?')[0];
    let params = url.split('?')[1].split('&');
    let navigateParam = {};

    for (var i = 0; i < params.length; i++) {
      let param = params[i].split('=');
      if (param[0] == 'p') {
        continue;
      }
      navigateParam[param[0]] = param[1];
    }

    this.router.navigate([path], { queryParams: navigateParam });
  }

  public toHome() {
    this.router.navigateByUrl('/home');
  }

  public getClickedRouterLink() {
    return this.clickedRouterLink;
  }

  public setClickedRouterLink(flg: boolean) {
    this.clickedRouterLink = flg;
  }

  public goBack() {
    this.location.back();
  }
}
