<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="campaign_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [class.__modalStyle__]="isShopDetailNav"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
>
  <main class="main mainCampaign content_top">
    <!-- 店舗詳細見出し -->
    <section class="campaignHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="navigationController.setClickedRouterLink(true)"
              [class.__modalStyle__]="isShopDetailNav"
              >{{breadcrumbsData.title}}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span class="campaignHeading__backLink" (click)="navigationController.goBack()"><</span>
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="campaignHeading_container secContainer">
        <h2 class="campaignHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">{{pageData.title}}</h2>
      </div>
      <div
        class="campaignBlock blockArea"
        *ngIf="blockData['campaign__titleBlock'] && blockData['campaign__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['campaign__titleBlock']"
          [position]="'campaign__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="campaignHeading_container secContainer">
        <p
          class="campaignHeading__desc"
          [innerHTML]="pageData.concept | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
      </div>
      <div
        class="campaignBlock blockArea"
        *ngIf="blockData['campaign__conceptBlock'] && blockData['campaign__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['campaign__conceptBlock']"
          [position]="'campaign__conceptBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.campaignHeading_container -->
    </section>
    <!-- /.campaignHeading -->

    <!-- キャンペーンコンテンツ -->
    <section class="campaignContent sec">
      <div class="campaignContent_container" *ngIf="!pageData.isEnterpriseLayout">
        <p
          class="campaignContent__message"
          *ngIf="pageData.coupon.length == 0"
          [class.__modalStyle__]="isShopDetailNav"
        >
          新着情報はありません
        </p>

        <!-- キャンペーン情報-->
        <!-- 個別キャンペーン -->
        <div class="coupon_item" [class.__modalStyle__]="isShopDetailNav" *ngFor="let c of pageData.coupon">
          <div
            class="campaignContent__each campaignContent__each__visualTrue"
            *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage; else elseContent"
            id="{{ 'campaignId-' + c.id }}"
            #campaign
          >
            <div class="campaignContent__each__textInfo">
              <h3
                class="campaignContent__each__textInfo__title jpfont"
                [innerHTML]="c.title"
                [class.__modalStyle__]="isShopDetailNav"
              ></h3>
              <h4
                class="campaignContent__each__textInfo__beforePrice jpfont"
                *ngIf="c.price"
                [class.__modalStyle__]="isShopDetailNav"
              >
                ￥
              </h4>
              <h4
                class="campaignContent__each__textInfo__beforePrice line jpfont"
                *ngIf="c.price"
                [class.__modalStyle__]="isShopDetailNav"
              >
                {{ c.price }}
              </h4>
              <h4
                class="campaignContent__each__textInfo__priceArrow jpfont"
                *ngIf="c.price && c.discount"
                [class.__modalStyle__]="isShopDetailNav"
              >
                &rarr;
              </h4>
              <h4
                class="campaignContent__each__textInfo__campaignPrice jpfont"
                *ngIf="c.discount"
                [class.__modalStyle__]="isShopDetailNav"
              >
                ￥{{ c.discount }}
              </h4>
              <p
                class="campaignContent__each__textInfo__desc"
                [innerHTML]="c.detail | htmlSanitize"
                [class.__modalStyle__]="isShopDetailNav"
              ></p>
              <div class="campaignContent__each__textInfo__detail">
                <div
                  class="campaignContent__each__textInfo__detail__group"
                  *ngIf="c.inform"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  <p
                    class="campaignContent__each__textInfo__detail__title"
                    *ngIf="c.inform"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    提示条件 |
                  </p>
                  <p
                    class="campaignContent__each__textInfo__detail__content"
                    *ngIf="c.inform"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    {{c.inform}}
                  </p>
                </div>
                <div class="campaignContent__each__textInfo__detail__group forpc">
                  <p class="campaignContent__each__textInfo__detail__title" [class.__modalStyle__]="isShopDetailNav">
                    有効期限 |
                  </p>
                  <p class="campaignContent__each__textInfo__detail__content" [class.__modalStyle__]="isShopDetailNav">
                    {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                  </p>
                </div>
                <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                  <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                  <p class="campaignContent__each__textInfo__detail__content">
                    {{c.periodStart}}<br />〜{{c.periodEnd}}
                  </p>
                </div> -->
                <div
                  class="campaignContent__each__textInfo__detail__group"
                  *ngIf="c.cond"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  <p
                    class="campaignContent__each__textInfo__detail__title"
                    *ngIf="c.cond"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    使用条件 |
                  </p>
                  <p
                    class="campaignContent__each__textInfo__detail__content"
                    *ngIf="c.cond"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    {{c.cond}}
                  </p>
                </div>
              </div>
              <!-- /.campaignContent__each__textInfo__detail-->
            </div>
            <!-- /.campaignContent__textInfo -->

            <div *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage" class="campaignContent__each__visual">
              <div class="campaignContent__each__visual__inner">
                <picture *ngIf="c.imageDisplayType != 4">
                  <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpImage" />
                  <img
                    class="campaignContent__each__visual__inner__img"
                    [defaultImage]="loadingImage"
                    [lazyLoad]="c.image"
                    alt="{{ c.imageAlt }}"
                    [class.width90]="c.imageDisplayType == 2"
                    [class.width50]="c.imageDisplayType == 3"
                  />
                </picture>
                <div
                  class="campaignContent__each__visual__inner__video iframe_campaign"
                  *ngIf="c.imageDisplayType == 4"
                  [innerHTML]="c.imageHtml"
                  #videoIframeContent
                ></div>
              </div>
            </div>
            <!-- /.campaignContent__visual -->
          </div>
          <!-- .campaignContent__each -->

          <ng-template #elseContent>
            <div class="campaignContent__each" id="{{ 'campaignId-' + c.id }}" #campaign>
              <div class="campaignContent__each__textInfo">
                <h3
                  class="campaignContent__each__textInfo__title jpfont"
                  [innerHTML]="c.title"
                  [class.__modalStyle__]="isShopDetailNav"
                ></h3>
                <h4
                  class="campaignContent__each__textInfo__beforePrice jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥
                </h4>
                <h4
                  class="campaignContent__each__textInfo__beforePrice line jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ c.price }}
                </h4>
                <h4
                  class="campaignContent__each__textInfo__priceArrow jpfont"
                  *ngIf="c.price && c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  &rarr;
                </h4>
                <h4
                  class="campaignContent__each__textInfo__campaignPrice jpfont"
                  *ngIf="c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥{{ c.discount }}
                </h4>
                <p
                  class="campaignContent__each__textInfo__desc"
                  [innerHTML]="c.detail | htmlSanitize"
                  [class.__modalStyle__]="isShopDetailNav"
                ></p>
                <div class="campaignContent__each__textInfo__detail">
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.inform"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      提示条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.inform}}
                    </p>
                  </div>
                  <div class="campaignContent__each__textInfo__detail__group forpc">
                    <p class="campaignContent__each__textInfo__detail__title" [class.__modalStyle__]="isShopDetailNav">
                      有効期限 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                    </p>
                  </div>
                  <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                    <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                    <p class="campaignContent__each__textInfo__detail__content">
                      {{c.periodStart}}<br />〜{{c.periodEnd}}
                    </p>
                  </div> -->
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.cond"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      使用条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.cond}}
                    </p>
                  </div>
                </div>
                <!-- /.campaignContent__each__textInfo__detail-->
              </div>
              <!-- /.campaignContent__textInfo -->
            </div>
            <!-- .campaignContent__each -->
          </ng-template>
        </div>
      </div>

      <!-- エンタープライズの場合 -->
      <ng-container *ngIf="pageData.isEnterpriseLayout">
        <!-- 店舗クーポン、本部クーポンどちらもデータ取得できた場合 -->
        <div
          class="tab-wrap"
          *ngIf="pageData.coupon.length > 0 && pageData.companyCoupon.length > 0"
          [class.__modalStyle__]="isShopDetailNav"
        >
          <input type="radio" class="tab-switch" [checked]="isCheckShop" /><label
            class="tab-label"
            [class.__modalStyle__]="isShopDetailNav"
            (click)="isCheckShop = true;"
            >限定</label
          >
          <div class="tab-content">
            <div class="campaignContent_container" #shopCampaignGroup>
              <!-- キャンペーン情報-->
              <!-- 個別キャンペーン -->
              <div class="coupon_item" [class.__modalStyle__]="isShopDetailNav" *ngFor="let c of pageData.coupon">
                <div
                  class="campaignContent__each campaignContent__each__visualTrue"
                  *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage; else elseContent"
                  id="{{ 'campaignId-' + c.id }}"
                  #campaign
                >
                  <div class="campaignContent__each__textInfo">
                    <h3
                      class="campaignContent__each__textInfo__title jpfont"
                      [innerHTML]="c.title"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></h3>
                    <h4
                      class="campaignContent__each__textInfo__beforePrice jpfont"
                      *ngIf="c.price"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      ￥
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__beforePrice line jpfont"
                      *ngIf="c.price"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ c.price }}
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__priceArrow jpfont"
                      *ngIf="c.price && c.discount"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      &rarr;
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__campaignPrice jpfont"
                      *ngIf="c.discount"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      ￥{{ c.discount }}
                    </h4>
                    <p
                      class="campaignContent__each__textInfo__desc"
                      [innerHTML]="c.detail | htmlSanitize"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                    <div class="campaignContent__each__textInfo__detail">
                      <div
                        class="campaignContent__each__textInfo__detail__group"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          提示条件 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.inform}}
                        </p>
                      </div>
                      <div class="campaignContent__each__textInfo__detail__group forpc">
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          有効期限 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                        </p>
                      </div>
                      <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                        <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                        <p class="campaignContent__each__textInfo__detail__content">
                          {{c.periodStart}}<br />〜{{c.periodEnd}}
                        </p>
                      </div> -->
                      <div
                        class="campaignContent__each__textInfo__detail__group"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          使用条件 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.cond}}
                        </p>
                      </div>
                    </div>
                    <!-- /.campaignContent__each__textInfo__detail-->
                  </div>
                  <!-- /.campaignContent__textInfo -->

                  <div *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage" class="campaignContent__each__visual">
                    <div class="campaignContent__each__visual__inner">
                      <picture *ngIf="c.imageDisplayType != 4">
                        <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpImage" />
                        <img
                          class="campaignContent__each__visual__inner__img"
                          [defaultImage]="loadingImage"
                          [lazyLoad]="c.image"
                          alt="{{ c.imageAlt }}"
                          [class.width90]="c.imageDisplayType == 2"
                          [class.width50]="c.imageDisplayType == 3"
                        />
                      </picture>
                      <div
                        class="campaignContent__each__visual__inner__video iframe_campaign"
                        *ngIf="c.imageDisplayType == 4"
                        [innerHTML]="c.imageHtml"
                        #videoIframeContent
                      ></div>
                    </div>
                  </div>
                  <!-- /.campaignContent__visual -->
                </div>
                <!-- .campaignContent__each -->

                <ng-template #elseContent>
                  <div class="campaignContent__each" id="{{ 'campaignId-' + c.id }}" #campaign>
                    <div class="campaignContent__each__textInfo">
                      <h3
                        class="campaignContent__each__textInfo__title jpfont"
                        [innerHTML]="c.title"
                        [class.__modalStyle__]="isShopDetailNav"
                      ></h3>
                      <h4
                        class="campaignContent__each__textInfo__beforePrice jpfont"
                        *ngIf="c.price"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        ￥
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__beforePrice line jpfont"
                        *ngIf="c.price"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{ c.price }}
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__priceArrow jpfont"
                        *ngIf="c.price && c.discount"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        &rarr;
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__campaignPrice jpfont"
                        *ngIf="c.discount"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        ￥{{ c.discount }}
                      </h4>
                      <p
                        class="campaignContent__each__textInfo__desc"
                        [innerHTML]="c.detail | htmlSanitize"
                        [class.__modalStyle__]="isShopDetailNav"
                      ></p>
                      <div class="campaignContent__each__textInfo__detail">
                        <div
                          class="campaignContent__each__textInfo__detail__group"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            *ngIf="c.inform"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            提示条件 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            *ngIf="c.inform"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.inform}}
                          </p>
                        </div>
                        <div class="campaignContent__each__textInfo__detail__group forpc">
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            有効期限 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                          </p>
                        </div>
                        <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                          <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                          <p class="campaignContent__each__textInfo__detail__content">
                            {{c.periodStart}}<br />〜{{c.periodEnd}}
                          </p>
                        </div> -->
                        <div
                          class="campaignContent__each__textInfo__detail__group"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            *ngIf="c.cond"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            使用条件 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            *ngIf="c.cond"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.cond}}
                          </p>
                        </div>
                      </div>
                      <!-- /.campaignContent__each__textInfo__detail-->
                    </div>
                    <!-- /.campaignContent__textInfo -->
                  </div>
                  <!-- .campaignContent__each -->
                </ng-template>
              </div>
            </div>
          </div>
          <input type="radio" class="tab-switch" [checked]="!isCheckShop" /><label
            class="tab-label"
            [class.__modalStyle__]="isShopDetailNav"
            (click)="isCheckShop = false;"
            >共通</label
          >
          <div class="tab-content">
            <div class="campaignContent_container">
              <!-- キャンペーン情報-->
              <!-- 本部キャンペーン -->
              <div
                class="coupon_item"
                [class.__modalStyle__]="isShopDetailNav"
                *ngFor="let c of pageData.companyCoupon"
              >
                <div
                  class="campaignContent__each campaignContent__each__visualTrue"
                  *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage; else elseContent"
                  id="{{ 'campaignId-' + c.id }}"
                  #campaign
                >
                  <div class="campaignContent__each__textInfo">
                    <h3
                      class="campaignContent__each__textInfo__title jpfont"
                      [innerHTML]="c.title"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></h3>
                    <h4
                      class="campaignContent__each__textInfo__beforePrice jpfont"
                      *ngIf="c.price"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      ￥
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__beforePrice line jpfont"
                      *ngIf="c.price"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ c.price }}
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__priceArrow jpfont"
                      *ngIf="c.price && c.discount"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      &rarr;
                    </h4>
                    <h4
                      class="campaignContent__each__textInfo__campaignPrice jpfont"
                      *ngIf="c.discount"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      ￥{{ c.discount }}
                    </h4>
                    <p
                      class="campaignContent__each__textInfo__desc"
                      [innerHTML]="c.detail | htmlSanitize"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                    <div class="campaignContent__each__textInfo__detail">
                      <div
                        class="campaignContent__each__textInfo__detail__group"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          提示条件 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.inform}}
                        </p>
                      </div>
                      <div class="campaignContent__each__textInfo__detail__group forpc">
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          有効期限 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                        </p>
                      </div>
                      <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                        <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                        <p class="campaignContent__each__textInfo__detail__content">
                          {{c.periodStart}}<br />〜{{c.periodEnd}}
                        </p>
                      </div> -->
                      <div
                        class="campaignContent__each__textInfo__detail__group"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        <p
                          class="campaignContent__each__textInfo__detail__title"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          使用条件 |
                        </p>
                        <p
                          class="campaignContent__each__textInfo__detail__content"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{c.cond}}
                        </p>
                      </div>
                    </div>
                    <!-- /.campaignContent__each__textInfo__detail-->
                  </div>
                  <!-- /.campaignContent__textInfo -->

                  <div *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage" class="campaignContent__each__visual">
                    <div class="campaignContent__each__visual__inner">
                      <picture *ngIf="c.imageDisplayType != 4">
                        <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpImage" />
                        <img
                          class="campaignContent__each__visual__inner__img"
                          [defaultImage]="loadingImage"
                          [lazyLoad]="c.image"
                          alt="{{ c.imageAlt }}"
                          [class.width90]="c.imageDisplayType == 2"
                          [class.width50]="c.imageDisplayType == 3"
                        />
                      </picture>
                      <div
                        class="campaignContent__each__visual__inner__video iframe_campaign"
                        *ngIf="c.imageDisplayType == 4"
                        [innerHTML]="c.imageHtml"
                        #videoIframeContent
                      ></div>
                    </div>
                  </div>
                  <!-- /.campaignContent__visual -->
                </div>
                <!-- .campaignContent__each -->

                <ng-template #elseContent>
                  <div class="campaignContent__each" id="{{ 'campaignId-' + c.id }}" #campaign>
                    <div class="campaignContent__each__textInfo">
                      <h3
                        class="campaignContent__each__textInfo__title jpfont"
                        [innerHTML]="c.title"
                        [class.__modalStyle__]="isShopDetailNav"
                      ></h3>
                      <h4
                        class="campaignContent__each__textInfo__beforePrice jpfont"
                        *ngIf="c.price"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        ￥
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__beforePrice line jpfont"
                        *ngIf="c.price"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{ c.price }}
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__priceArrow jpfont"
                        *ngIf="c.price && c.discount"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        &rarr;
                      </h4>
                      <h4
                        class="campaignContent__each__textInfo__campaignPrice jpfont"
                        *ngIf="c.discount"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        ￥{{ c.discount }}
                      </h4>
                      <p
                        class="campaignContent__each__textInfo__desc"
                        [innerHTML]="c.detail | htmlSanitize"
                        [class.__modalStyle__]="isShopDetailNav"
                      ></p>
                      <div class="campaignContent__each__textInfo__detail">
                        <div
                          class="campaignContent__each__textInfo__detail__group"
                          *ngIf="c.inform"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            *ngIf="c.inform"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            提示条件 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            *ngIf="c.inform"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.inform}}
                          </p>
                        </div>
                        <div class="campaignContent__each__textInfo__detail__group forpc">
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            有効期限 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                          </p>
                        </div>
                        <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                          <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                          <p class="campaignContent__each__textInfo__detail__content">
                            {{c.periodStart}}<br />〜{{c.periodEnd}}
                          </p>
                        </div> -->
                        <div
                          class="campaignContent__each__textInfo__detail__group"
                          *ngIf="c.cond"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          <p
                            class="campaignContent__each__textInfo__detail__title"
                            *ngIf="c.cond"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            使用条件 |
                          </p>
                          <p
                            class="campaignContent__each__textInfo__detail__content"
                            *ngIf="c.cond"
                            [class.__modalStyle__]="isShopDetailNav"
                          >
                            {{c.cond}}
                          </p>
                        </div>
                      </div>
                      <!-- /.campaignContent__each__textInfo__detail-->
                    </div>
                    <!-- /.campaignContent__textInfo -->
                  </div>
                  <!-- .campaignContent__each -->
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <!-- 店舗クーポン、本部クーポンどちらも取得できなかった場合 -->
        <div
          class="campaignContent_container"
          *ngIf="pageData.coupon.length == 0 && pageData.companyCoupon.length == 0"
        >
          <p class="campaignContent__message" [class.__modalStyle__]="isShopDetailNav">新着情報はありません</p>
        </div>

        <!-- 店舗クーポンのみ取得できた場合 -->
        <div class="campaignContent_container" *ngIf="pageData.coupon.length > 0 && pageData.companyCoupon.length == 0">
          <!-- キャンペーン情報-->
          <!-- 個別キャンペーン -->
          <div class="coupon_item" [class.__modalStyle__]="isShopDetailNav" *ngFor="let c of pageData.coupon">
            <div
              class="campaignContent__each campaignContent__each__visualTrue"
              *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage; else elseContent"
              id="{{ 'campaignId-' + c.id }}"
              #campaign
            >
              <div class="campaignContent__each__textInfo">
                <h3
                  class="campaignContent__each__textInfo__title jpfont"
                  [innerHTML]="c.title"
                  [class.__modalStyle__]="isShopDetailNav"
                ></h3>
                <h4
                  class="campaignContent__each__textInfo__beforePrice jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥
                </h4>
                <h4
                  class="campaignContent__each__textInfo__beforePrice line jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ c.price }}
                </h4>
                <h4
                  class="campaignContent__each__textInfo__priceArrow jpfont"
                  *ngIf="c.price && c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  &rarr;
                </h4>
                <h4
                  class="campaignContent__each__textInfo__campaignPrice jpfont"
                  *ngIf="c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥{{ c.discount }}
                </h4>
                <p
                  class="campaignContent__each__textInfo__desc"
                  [innerHTML]="c.detail | htmlSanitize"
                  [class.__modalStyle__]="isShopDetailNav"
                ></p>
                <div class="campaignContent__each__textInfo__detail">
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.inform"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      提示条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.inform}}
                    </p>
                  </div>
                  <div class="campaignContent__each__textInfo__detail__group forpc">
                    <p class="campaignContent__each__textInfo__detail__title" [class.__modalStyle__]="isShopDetailNav">
                      有効期限 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                    </p>
                  </div>
                  <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                    <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                    <p class="campaignContent__each__textInfo__detail__content">
                      {{c.periodStart}}<br />〜{{c.periodEnd}}
                    </p>
                  </div> -->
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.cond"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      使用条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.cond}}
                    </p>
                  </div>
                </div>
                <!-- /.campaignContent__each__textInfo__detail-->
              </div>
              <!-- /.campaignContent__textInfo -->

              <div *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage" class="campaignContent__each__visual">
                <div class="campaignContent__each__visual__inner">
                  <picture *ngIf="c.imageDisplayType != 4">
                    <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpImage" />
                    <img
                      class="campaignContent__each__visual__inner__img"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="c.image"
                      alt="{{ c.imageAlt }}"
                      [class.width90]="c.imageDisplayType == 2"
                      [class.width50]="c.imageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="campaignContent__each__visual__inner__video iframe_campaign"
                    *ngIf="c.imageDisplayType == 4"
                    [innerHTML]="c.imageHtml"
                    #videoIframeContent
                  ></div>
                </div>
              </div>
              <!-- /.campaignContent__visual -->
            </div>
            <!-- .campaignContent__each -->

            <ng-template #elseContent>
              <div class="campaignContent__each" id="{{ 'campaignId-' + c.id }}" #campaign>
                <div class="campaignContent__each__textInfo">
                  <h3
                    class="campaignContent__each__textInfo__title jpfont"
                    [innerHTML]="c.title"
                    [class.__modalStyle__]="isShopDetailNav"
                  ></h3>
                  <h4
                    class="campaignContent__each__textInfo__beforePrice jpfont"
                    *ngIf="c.price"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    ￥
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__beforePrice line jpfont"
                    *ngIf="c.price"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    {{ c.price }}
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__priceArrow jpfont"
                    *ngIf="c.price && c.discount"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    &rarr;
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__campaignPrice jpfont"
                    *ngIf="c.discount"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    ￥{{ c.discount }}
                  </h4>
                  <p
                    class="campaignContent__each__textInfo__desc"
                    [innerHTML]="c.detail | htmlSanitize"
                    [class.__modalStyle__]="isShopDetailNav"
                  ></p>
                  <div class="campaignContent__each__textInfo__detail">
                    <div
                      class="campaignContent__each__textInfo__detail__group"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        提示条件 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.inform}}
                      </p>
                    </div>
                    <div class="campaignContent__each__textInfo__detail__group forpc">
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        有効期限 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                      </p>
                    </div>
                    <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                      <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                      <p class="campaignContent__each__textInfo__detail__content">
                        {{c.periodStart}}<br />〜{{c.periodEnd}}
                      </p>
                    </div> -->
                    <div
                      class="campaignContent__each__textInfo__detail__group"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        使用条件 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.cond}}
                      </p>
                    </div>
                  </div>
                  <!-- /.campaignContent__each__textInfo__detail-->
                </div>
                <!-- /.campaignContent__textInfo -->
              </div>
              <!-- .campaignContent__each -->
            </ng-template>
          </div>
        </div>

        <!-- 本部クーポンのみ取得できた場合 -->
        <div class="campaignContent_container" *ngIf="pageData.coupon.length == 0 && pageData.companyCoupon.length > 0">
          <!-- キャンペーン情報-->
          <!-- 本部キャンペーン -->
          <div class="coupon_item" [class.__modalStyle__]="isShopDetailNav" *ngFor="let c of pageData.companyCoupon">
            <div
              class="campaignContent__each campaignContent__each__visualTrue"
              *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage; else elseContent"
              id="{{ 'campaignId-' + c.id }}"
              #campaign
            >
              <div class="campaignContent__each__textInfo">
                <h3
                  class="campaignContent__each__textInfo__title jpfont"
                  [innerHTML]="c.title"
                  [class.__modalStyle__]="isShopDetailNav"
                ></h3>
                <h4
                  class="campaignContent__each__textInfo__beforePrice jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥
                </h4>
                <h4
                  class="campaignContent__each__textInfo__beforePrice line jpfont"
                  *ngIf="c.price"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ c.price }}
                </h4>
                <h4
                  class="campaignContent__each__textInfo__priceArrow jpfont"
                  *ngIf="c.price && c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  &rarr;
                </h4>
                <h4
                  class="campaignContent__each__textInfo__campaignPrice jpfont"
                  *ngIf="c.discount"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  ￥{{ c.discount }}
                </h4>
                <p
                  class="campaignContent__each__textInfo__desc"
                  [innerHTML]="c.detail | htmlSanitize"
                  [class.__modalStyle__]="isShopDetailNav"
                ></p>
                <div class="campaignContent__each__textInfo__detail">
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.inform"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      提示条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.inform}}
                    </p>
                  </div>
                  <div class="campaignContent__each__textInfo__detail__group forpc">
                    <p class="campaignContent__each__textInfo__detail__title" [class.__modalStyle__]="isShopDetailNav">
                      有効期限 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                    </p>
                  </div>
                  <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                    <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                    <p class="campaignContent__each__textInfo__detail__content">
                      {{c.periodStart}}<br />〜{{c.periodEnd}}
                    </p>
                  </div> -->
                  <div
                    class="campaignContent__each__textInfo__detail__group"
                    *ngIf="c.cond"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <p
                      class="campaignContent__each__textInfo__detail__title"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      使用条件 |
                    </p>
                    <p
                      class="campaignContent__each__textInfo__detail__content"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{c.cond}}
                    </p>
                  </div>
                </div>
                <!-- /.campaignContent__each__textInfo__detail-->
              </div>
              <!-- /.campaignContent__textInfo -->

              <div *ngIf="(c.image || c.imageIframe ) && c.isDisplayImage" class="campaignContent__each__visual">
                <div class="campaignContent__each__visual__inner">
                  <img
                    *ngIf="c.imageDisplayType != 4"
                    class="campaignContent__each__visual__inner__img"
                    [defaultImage]="loadingImage"
                    [lazyLoad]="c.image"
                    alt="{{c.imageAlt}}"
                    [class.width90]="c.imageDisplayType == 2"
                    [class.width50]="c.imageDisplayType == 3"
                  />
                  <div
                    class="campaignContent__each__visual__inner__video iframe_campaign"
                    *ngIf="c.imageDisplayType == 4"
                    [innerHTML]="c.imageHtml"
                    #videoIframeContent
                  ></div>
                </div>
              </div>
              <!-- /.campaignContent__visual -->
            </div>
            <!-- .campaignContent__each -->

            <ng-template #elseContent>
              <div class="campaignContent__each" id="{{ 'campaignId-' + c.id }}" #campaign>
                <div class="campaignContent__each__textInfo">
                  <h3
                    class="campaignContent__each__textInfo__title jpfont"
                    [innerHTML]="c.title"
                    [class.__modalStyle__]="isShopDetailNav"
                  ></h3>
                  <h4
                    class="campaignContent__each__textInfo__beforePrice jpfont"
                    *ngIf="c.price"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    ￥
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__beforePrice line jpfont"
                    *ngIf="c.price"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    {{ c.price }}
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__priceArrow jpfont"
                    *ngIf="c.price && c.discount"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    &rarr;
                  </h4>
                  <h4
                    class="campaignContent__each__textInfo__campaignPrice jpfont"
                    *ngIf="c.discount"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    ￥{{ c.discount }}
                  </h4>
                  <p
                    class="campaignContent__each__textInfo__desc"
                    [innerHTML]="c.detail | htmlSanitize"
                    [class.__modalStyle__]="isShopDetailNav"
                  ></p>
                  <div class="campaignContent__each__textInfo__detail">
                    <div
                      class="campaignContent__each__textInfo__detail__group"
                      *ngIf="c.inform"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        提示条件 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        *ngIf="c.inform"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.inform}}
                      </p>
                    </div>
                    <div class="campaignContent__each__textInfo__detail__group forpc">
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        有効期限 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.periodStart | date:'yyyy/MM/dd'}}〜{{c.periodEnd | date:'yyyy/MM/dd'}}
                      </p>
                    </div>
                    <!-- <div class="campaignContent__each__textInfo__detail__group formobile">
                      <p class="campaignContent__each__textInfo__detail__title">有効期限 |</p>
                      <p class="campaignContent__each__textInfo__detail__content">
                        {{c.periodStart}}<br />〜{{c.periodEnd}}
                      </p>
                    </div> -->
                    <div
                      class="campaignContent__each__textInfo__detail__group"
                      *ngIf="c.cond"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      <p
                        class="campaignContent__each__textInfo__detail__title"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        使用条件 |
                      </p>
                      <p
                        class="campaignContent__each__textInfo__detail__content"
                        *ngIf="c.cond"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{c.cond}}
                      </p>
                    </div>
                  </div>
                  <!-- /.campaignContent__each__textInfo__detail-->
                </div>
                <!-- /.campaignContent__textInfo -->
              </div>
              <!-- .campaignContent__each -->
            </ng-template>
          </div>
        </div>
      </ng-container>
    </section>
    <!-- /.campaignContent -->
  </main>
  <!-- /.mainStoreDetail -->

  <!-- フッター -->
  <app-footer
    [shopName]="this.shopName"
    [isShopDetailNav]="isShopDetailNav"
    class="appFooter"
    [class.__modalStyle__]="isShopDetailNav"
  ></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
