import { Injectable } from '@angular/core';

// イベント発火のための Subject を import
import { Subject } from 'rxjs';
import { OpenPageInfo } from '../interfaces/openPageInfo.interface';

@Injectable()
export class NoticeOpenLinkFromAppCom {
  /**
   * データの変更を通知するためのオブジェクト
   *
   * @private
   * @memberof NoticeOpenLinkFromAppCom
   */
  private sharedDataSource = new Subject<OpenPageInfo>();

  /**
   * Subscribe するためのプロパティ
   * `- コンポーネント間で共有するためのプロパティ
   *
   * @memberof NoticeOpenLinkFromAppCom
   */
  public sharedDataSource$ = this.sharedDataSource.asObservable();

  /**
   * コンストラクタ. NoticeOpenLinkFromAppCom のインスタンスを生成する
   *
   * @memberof NoticeOpenLinkFromAppCom
   */
  constructor() {}

  /**
   * データの更新イベント
   *
   * @param {boolean} updated 更新データ
   * @memberof NoticeOpenLinkFromAppCom
   */
  public onNotifySharedDataChanged(updated: OpenPageInfo) {
    this.sharedDataSource.next(updated);
  }
}
