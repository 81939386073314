import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reservation-list-svg',
  templateUrl: './reservation-list-svg.component.html',
  styleUrls: ['./reservation-list-svg.component.scss']
})
export class ReservationListSvgComponent implements OnInit {
  @Input() isShopDetailNav: boolean = false;
  constructor() {}

  ngOnInit() {}
}
