import { Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable()
export class WindowService {
  private windowReference: Window | null;
  /*
   get nativeWindow() : any {
      return _window();
   }
   */
  get orientation(): any {
    return _window().orientation;
  }
  get innerWidth(): any {
    return _window().innerWidth;
  }
  get innerHeight(): any {
    return _window().innerHeight;
  }
  get isMobileView(): boolean {
    let isLandscape = false;
    if (innerWidth > innerHeight) {
      isLandscape = true;
    }
    if ((innerWidth <= 1366 && isLandscape) || (innerWidth <= 1024 && !isLandscape)) {
      return true;
    } else {
      return false;
    }
  }
  // 各コンポーネントのスクロール位置を一番上まで戻す
  scrollToTop(content: IonContent): void {
    content.scrollToTop(600);
  }

  // ユーザーアクションからopenWindowまで時間がかかってポップアップブロックされるときは
  // ユーザーアクション後にすぐthis.windowService.setWindowReference(window.open()) をしておく
  public setWindowReference(windowReference: Window): void {
    this.windowReference = windowReference;
  }

  public openWindow(url: string): void {
    if (this.windowReference != null) {
      this.windowReference.location = url;
      this.setWindowReference(null);
    } else {
      if (!window.open(url, '_blank')) {
        // ブロックしていたら自身のウィンドウを変える（Safari対策）
        window.location.href = url;
      }
    }
  }

  public isRendertron(): boolean {
    return _window().navigator.userAgent.includes('HeadlessChrome');
  }

  public setPrerenderReady(isReady: boolean): void {
    if (isReady) {
      _window().prerenderReady = true;
    } else {
      _window().prerenderReady = false;
    }
  }
}
