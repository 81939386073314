<svg
  class="nav_store_list_svg"
  [class.__modalStyle__]="isShopDetailNav"
  version="1.0"
  id="レイヤー_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="57px"
  height="36px"
  viewBox="0 0 57 36"
  enable-background="new 0 0 57 36"
  xml:space="preserve"
>
  <g class="nav_store_list">
    <path class="st0" d="M24.9,16.3h11.5 M24.9,20.8h11.5 M24.9,25.3h11.5" />
    <circle class="st0" cx="22.1" cy="16.3" r="0.7" />
    <circle class="st0" cx="22.1" cy="20.8" r="0.7" />
    <circle class="st0" cx="22.1" cy="25.3" r="0.7" />
    <circle class="st0" cx="28.5" cy="20.8" r="13" />
  </g>

  <g class="nav_store_list_active">
    <path
      d="M28.5,7.1c-7.6,0-13.7,6.2-13.7,13.7s6.2,13.7,13.7,13.7s13.7-6.2,13.7-13.7S36.1,7.1,28.5,7.1z M22.1,26.7
	c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4S22.9,26.7,22.1,26.7z M22.1,22.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
	s1.4,0.6,1.4,1.4S22.9,22.2,22.1,22.2z M22.1,17.7c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4S22.9,17.7,22.1,17.7z
	 M36.4,26H24.9v-1.4h11.5V26z M36.4,21.5H24.9v-1.4h11.5V21.5z M36.4,17H24.9v-1.4h11.5V17z"
    />
  </g>
</svg>
