import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BlockComponent } from './block.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, LazyLoadImageModule],
  declarations: [BlockComponent],
  exports: [BlockComponent]
})
export class BlockComponentModule {}
