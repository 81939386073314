<svg
  class="nav_reservation_list_svg"
  [class.__modalStyle__]="isShopDetailNav"
  version="1.0"
  id="レイヤー_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="57px"
  height="36px"
  viewBox="0 0 57 36"
  enable-background="new 0 0 57 36"
  xml:space="preserve"
>
  <g class="nav_reservation_list">
    <path d="M39,13v18H18V13H39 M40.5,11.5h-24v21h24V11.5L40.5,11.5z" />
    <line stroke-width="1.5" stroke-miterlimit="10" x1="16.5" y1="17.5" x2="40.5" y2="17.5" />
    <line stroke-width="2" stroke-miterlimit="10" x1="23.5" y1="9.5" x2="23.5" y2="11.5" />
    <line stroke-width="2" stroke-miterlimit="10" x1="23.5" y1="11.5" x2="23.5" y2="14.5" />
    <line stroke-width="2" stroke-miterlimit="10" x1="33.5" y1="9.5" x2="33.5" y2="11.5" />
    <line stroke-width="2" stroke-miterlimit="10" x1="33.5" y1="11.5" x2="33.5" y2="14.5" />
    <polyline
      stroke-width="1.5"
      stroke-miterlimit="10"
      points="36.571,21.5 32.328,25.743 29.5,22.914
"
    />
  </g>

  <g class="nav_reservation_list_active">
    <rect x="22.5" y="9.5" width="2" height="2" />
    <rect x="32.5" y="9.5" width="2" height="2" />
    <g>
      <path
        d="M16.5,32.5h24V18.25h-24V32.5z M30.03,22.384l2.298,2.299l3.713-3.713l1.061,1.061l-4.773,4.773l-3.358-3.359L30.03,22.384z"
      />
      <path d="M16.5,11.5v5.25h24V11.5H16.5z M24.5,14.5h-2v-3h2V14.5z M34.5,14.5h-2v-3h2V14.5z" />
    </g>
  </g>
</svg>
