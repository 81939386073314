import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationController } from '../services/navigationController';
import { MetaDataController } from '../services/metaDataController';
import { MyPageService } from '../services/myPage.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { PagedataService } from '../services/pagedata.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ShopDetailModalService } from '../services/shopDetailModal.service';

@Component({
  selector: 'app-gallerymodal',
  templateUrl: './gallerymodal.component.html',
  styleUrls: ['./gallerymodal.component.scss']
})
export class GallerymodalComponent implements OnInit {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  showNext: boolean = true;
  showBack: boolean = true;
  panePos: number = 1;
  paneCount: number;
  flickStartX: number;
  flickEndX: number;
  isDoubleTouch: boolean;
  noPhoto: boolean = false;
  isCompany: boolean = false;
  selectedModal: any = [
    {
      id: null,
      url: null
    }
  ];
  selectedInfo: any = [
    {
      index: null,
      categoryId: null,
      url: null,
      title: null,
      staff: {
        id: null,
        name: null,
        staffPosition: null,
        photo: null
      },
      concept: null,
      images: null,
      instagramFlg: false
    }
  ];
  embedInstaUrl: SafeHtml;
  @Input() pageData: any;
  @Output() modalcontrol = new EventEmitter();
  @Input() dispModalStaffDetail: boolean;
  @Input() modalid: string;

  constructor(
    public pds: PagedataService,
    private elem: ElementRef,
    private metaDataController: MetaDataController,
    private navigationController: NavigationController,
    private myPageService: MyPageService,
    private sanitizer: DomSanitizer,
    public router: Router,
    private shopDetailModalService: ShopDetailModalService
  ) {
    this.insertInstagramScript();
  }

  ngOnInit() {}

  private insertInstagramScript() {
    var script = document.createElement('script');
    script.setAttribute('src', 'https://www.instagram.com/embed.js');
    document.body.appendChild(script);
  }

  loadEmbeds() {
    let win = <any>window;
    if (win.instgrm && win.instgrm.Embeds) {
      win.instgrm.Embeds.process();
    }
  }

  openModal(data) {
    // ギャラリーモーダルの左右切り替えフラグは開くごとにtrueの初期値をセットする
    this.showNext = true;
    this.showBack = true;
    this.selectedInfo = data;
    this.isCompany = this.pds.getIsCompany();
    console.log(this.selectedInfo);
    console.log(this.selectedInfo.staff);

    this.selectedModal = this.selectedInfo.images;
    this.modalcontrol.emit(true);

    // this.paneCount = this.pageData.gallery[id].images.length
    this.paneCount = this.selectedInfo.images.length;
    console.log(this.paneCount);

    this.noPhoto = false;
    if (this.selectedInfo.staff != null) {
      if (this.selectedInfo.staff.photo == null) {
        this.noPhoto = true;
      }
    }

    if (this.paneCount == 1) {
      this.showNext = false;
    }
    this.showBack = false;

    if (this.selectedInfo.instagramFlg) {
      this.embedInstaUrl = this.sanitizer.bypassSecurityTrustHtml(this.selectedInfo.images[0].instagramOembedUrl);
    } else {
      this.elem.nativeElement.querySelector('.modal__content__slider__photos').style.transform =
        'translateX(-' + (this.panePos - 1) * 100 + '%)';
    }
  }

  closeModal(modalid: string) {
    let self = this;
    if (!this.selectedInfo.instagramFlg) {
      // モーダルを閉じる前に、showクラスを先頭にセットし直す
      self.elem.nativeElement
        .querySelector('.modal__content__slider__photos')
        .querySelectorAll(':scope .modal__content__slider__photo.show')[0]
        .classList.remove('show');
      self.elem.nativeElement
        .querySelector('.modal__content__slider__photos')
        .querySelectorAll(':scope .modal__content__slider__photo')[0]
        .classList.add('show');
    }

    this.modalcontrol.emit(false);
    this.panePos = 1;
    this.showNext = true;
    this.showBack = true;
    // モーダルのスクロール位置を初期化
    this.metaDataController.scrollTop(modalid);
  }

  nextPane() {
    let self = this;

    self.panePos++;
    if (self.panePos === self.paneCount) {
      self.showNext = false;
      self.showBack = true;
    } else if (self.panePos !== self.paneCount) {
      self.showNext = true;
      self.showBack = true;
    }
    let imgList = self.elem.nativeElement
      .querySelector('.modal__content__slider__photos')
      .querySelectorAll(':scope .modal__content__slider__photo');
    imgList.forEach(function (img) {
      img.classList.add('show');
    });
    self.elem.nativeElement.querySelector('.modal__content__slider__photos').style.transform =
      'translateX(-' + (self.panePos - 1) * 100 + '%)';
    setTimeout(function () {
      imgList.forEach(function (img, i) {
        if (self.panePos - 1 != i) {
          img.classList.remove('show');
        }
      });
    }, 450);
  }

  backPane() {
    let self = this;

    self.panePos--;
    if (self.panePos === 1) {
      self.showBack = false;
      self.showNext = true;
    } else if (self.panePos !== 1) {
      self.showBack = true;
      self.showNext = true;
    }
    let imgList = self.elem.nativeElement
      .querySelector('.modal__content__slider__photos')
      .querySelectorAll(':scope .modal__content__slider__photo');
    imgList.forEach(function (img) {
      img.classList.add('show');
    });
    self.elem.nativeElement.querySelector('.modal__content__slider__photos').style.transform =
      'translateX(-' + (self.panePos - 1) * 100 + '%)';
    setTimeout(function () {
      imgList.forEach(function (img, i) {
        if (self.panePos - 1 != i) {
          img.classList.remove('show');
        }
      });
    }, 450);
  }

  touchstart(e) {
    // console.log("touchstart");
    // フリックの開始位置を保持する
    this.flickStartX = e.touches[0].pageX;
    // タッチ開始時は２本指操作フラグをfalseにする
    this.isDoubleTouch = false;
  }

  touchmove(e) {
    // console.log("touchmove");
    // フリックの終了位置を保持する
    this.flickEndX = e.touches[0].pageX;
    // ２本以上でタッチした場合
    if (e.touches.length >= 2) {
      this.isDoubleTouch = true;
    }
  }

  touchend(e) {
    // ２本操作の場合は画像送りをしない
    if (this.isDoubleTouch) {
      return false;
    }
    // console.log("touchend");
    if (this.flickEndX == 0) {
      return false;
    }

    // （画面の左が小さく、右が大きい）
    // フリック開始位置が終了位置より大きい場合（右から左にフリックした場合）
    if (this.flickStartX > this.flickEndX) {
      if (this.showNext) {
        // 右の画像に切り替える
        this.nextPane();
      }
      // フリック終了位置が開始位置より大きい場合（左から右にフリックした場合）
    } else if (this.flickStartX < this.flickEndX) {
      if (this.showBack) {
        // 左の画像に切り替える
        this.backPane();
      }
    }

    // フリック開始・終了位置の初期化
    this.flickStartX = 0;
    this.flickEndX = 0;
  }

  public async clickBooking(staffInfo: any) {
    const staffId: number = staffInfo.csStaffId != null ? staffInfo.csStaffId : -1;
    // エンタープライズ（店舗）を店舗詳細モーダルで開いた場合、salonNameに店舗名をセットする
    // ベーシック、アドバンス、エンタープライズ（本部）の場合、salonNameにはstaffInfoから取得した店舗名をセットする
    const param: ParamForReservation = {
      staffId,
      lastReservation: -1,
      salonName: this.shopName !== undefined ? this.shopName : staffInfo.salonInfo.accountName
    };
    this.myPageService.authenticateKey(MyPageService.RESERVATION, param);
  }

  // 店舗詳細モーダルを開く関数
  // モーダルを開いている場合：モーダル内に一覧画面を表示する
  // モーダルを開いてない場合：新しくモーダルを開く
  clickLink(shopDetail: string = null, pathName: string = null, requestParams: any = {}) {
    if (this.isShopDetailNav) {
      this.shopDetailModalService.pushPage(shopDetail, pathName, requestParams);
    } else {
      // エンタープライズ本部の場合は「詳細を見る」「もっとみる」でモーダルを開く
      if (this.pageData.salonData.appEnabled == 5) {
        this.shopDetailModalService.openShopDetailModal(shopDetail, pathName, requestParams);
      } else {
        // エンタープライズ本部以外の場合は「詳細を見る」「もっとみる」でページ遷移する
        delete requestParams['pageName'];
        // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
        this.router.navigate(['/' + pathName], { queryParams: requestParams });
        this.navigationController.setClickedRouterLink(true);
      }
    }
  }
}
