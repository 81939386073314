import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MenuPageData } from '../interfaces/menuPageData.interface';
import { ThemeData } from '../interfaces/themeData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { HomePageData } from '../interfaces/homePageData.interface';
import { StaffPageData } from '../interfaces/staffPageData.interface';
import { StaffDetailPageData } from '../interfaces/staffDetailPageData.interface';
import { SalonPageData } from '../interfaces/salonPageData.interface';
import { GalleryPageData } from '../interfaces/galleryPageData.interface';
import { CampaignPageData } from '../interfaces/campaignPageData.interface';
import { NewsPageData } from '../interfaces/newsPageData.interface';
import { NewsDetailPageData } from '../interfaces/newsDetailPageData.interface';
import { GallerySummaryData } from '../interfaces/gallerySummaryData.interface';
import { FreePageData } from '../interfaces/freePageData.interface';
import { ShopListData } from '../interfaces/shopListData.interface';
import { StringUtils } from '../utils/string.utils';

@Injectable({
  providedIn: 'root'
})
export class PagedataApiService {
  private pages_api_url;
  private contents_api_url;
  private dataHost: string;

  constructor(public http: HttpClient, public router: Router) {
    if (environment.isUseSameHost) {
      this.dataHost = `${location.protocol}//${location.host}`;
    } else {
      this.dataHost = environment.dataHost;
    }

    this.pages_api_url = `${this.dataHost}/rest/v1/pages/`;
    this.contents_api_url = `${this.dataHost}/rest/v1/contents/`;

    console.log(this.dataHost);
  }

  getDataHost() {
    return this.dataHost;
  }

  async getThemeData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<ThemeData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'theme/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Theme'];
      return pageData;
    }
  }

  async getLayoutData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<LayoutData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'layout/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Layout'];
      return pageData;
    }
  }

  async getHomePageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<HomePageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'home/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['home'];
      for (var i = 0; i < pageData.coupon.length; i++) {
        let price = pageData.coupon[i]['price'];
        pageData.coupon[i]['price'] = StringUtils.isBlank(price)
          ? null
          : price.match(/^[0-9]+$/)
          ? price.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : price;
        let discount = pageData.coupon[i]['discount'];
        pageData.coupon[i]['discount'] = StringUtils.isBlank(discount)
          ? null
          : discount.match(/^[0-9]+$/)
          ? discount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : discount;
        let periodStart = pageData.coupon[i]['periodStart'];
        pageData.coupon[i]['periodStart'] = periodStart.split(' ')[0];
        let periodEnd = pageData.coupon[i]['periodEnd'];
        pageData.coupon[i]['periodEnd'] = periodEnd.split(' ')[0];
      }
      return pageData;
    }
  }

  async getMenuPageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<MenuPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'menu/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['menu'];
      return pageData;
    }
  }

  async getStaffPageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<StaffPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'staff/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['staff'];
      return pageData;
    }
  }

  async getStaffDetailPageData(
    salonName: string,
    isPreview: boolean,
    staffId: number,
    isCompany: boolean
  ): Promise<StaffDetailPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'staffdetail/' + salonName + '/' + staffId;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['staffdetail'];
      return pageData;
    }
  }

  async getSalonPageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<SalonPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'salon/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['salon'];
      return pageData;
    }
  }

  async getGalleryPageData(
    salonName: string,
    isPreview: boolean,
    category: number,
    offset: number,
    num: number,
    isAllOrder: string,
    isCompany: boolean,
    brandId: number
  ): Promise<GalleryPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url =
      this.pages_api_url +
      'gallery/' +
      salonName +
      '?category=' +
      category +
      '&offset=' +
      offset +
      '&num=' +
      num +
      '&is_all_order=' +
      isAllOrder +
      '&brand_id=' +
      brandId;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '&' + urlSearchParam;

    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['gallery'];
      return pageData;
    }
  }

  async getCampaignPageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<CampaignPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'campaign/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['campaign'];
      for (var i = 0; i < pageData.coupon.length; i++) {
        let price = pageData.coupon[i]['price'];
        pageData.coupon[i]['price'] = StringUtils.isBlank(price)
          ? null
          : price.match(/^[0-9]+$/)
          ? price.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : price;
        let discount = pageData.coupon[i]['discount'];
        pageData.coupon[i]['discount'] = StringUtils.isBlank(discount)
          ? null
          : discount.match(/^[0-9]+$/)
          ? discount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : discount;
        let periodStart = pageData.coupon[i]['periodStart'];
        pageData.coupon[i]['periodStart'] = periodStart.split(' ')[0];
        let periodEnd = pageData.coupon[i]['periodEnd'];
        pageData.coupon[i]['periodEnd'] = periodEnd.split(' ')[0];
      }
      return pageData;
    }
  }

  async getNewsPageData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<NewsPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'news/' + salonName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['news'];
      return pageData;
    }
  }

  async getNewsDetailPageData(
    salonName: string,
    isPreview: boolean,
    newsId: number,
    isCompany: boolean
  ): Promise<NewsDetailPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'newsdetail/' + salonName + '/' + newsId;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['newsdetail'];
      return pageData;
    }
  }

  async getGalleryData(
    salonName: string,
    isPreview: boolean,
    category: number,
    offset: number,
    num: number,
    isAllOrder: string,
    isCompany: boolean
  ): Promise<GalleryPageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url =
      this.contents_api_url +
      'gallery/' +
      salonName +
      '?category=' +
      category +
      '&offset=' +
      offset +
      '&num=' +
      num +
      '&is_all_order=' +
      isAllOrder;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '&' + urlSearchParam;

    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['gallery'];
      return pageData;
    }
  }

  async getGalleryDataByStaff(
    salonName: string,
    isPreview: boolean,
    staffId: number,
    offset: number,
    num: number,
    isCompany: boolean
  ): Promise<GallerySummaryData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url =
      this.contents_api_url +
      'gallery_by_staff/' +
      salonName +
      '?staff_id=' +
      staffId +
      '&offset=' +
      offset +
      '&num=' +
      num;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '&' + urlSearchParam;

    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['staff'];
      return pageData;
    }
  }

  async getFreePageData(
    salonName: string,
    isPreview: boolean,
    pageName: string,
    isCompany: boolean
  ): Promise<FreePageData> {
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    let url = this.pages_api_url + 'free/' + salonName + '/' + pageName;
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['free'];
      return pageData;
    }
  }

  async getShopListData(salonName: string, isPreview: boolean, isCompany: boolean): Promise<ShopListData> {
    let url = this.contents_api_url + 'shop_list/' + salonName;
    let p_val = isPreview ? '1' : '0';
    let h_val = isCompany ? '1' : '0';
    const urlSearchParam = new URLSearchParams({ p: p_val, h: h_val }).toString();
    url = url + '/?' + urlSearchParam;

    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['shoplist'];
      return pageData;
    }
  }

  async getPublishedDate(salonName: string, isCompany: boolean): Promise<any> {
    let url = this.contents_api_url + 'published_date/' + salonName;
    if (isCompany) {
      url += '?h=1';
    } else {
      url += '?h=0';
    }
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['publisheddate'];
      return pageData;
    }
  }

  async getCheckTransitionData(salonName: string, isCompany: boolean): Promise<any> {
    let url = this.contents_api_url + 'check_transition/' + salonName;
    if (isCompany) {
      url += '?h=1';
    } else {
      url += '?h=0';
    }
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['checktransition'];
      if (Object.keys(pageData.salonData).length == 0) {
        location.href = pageData.redirectErrorPage404URL;
      } else {
        return pageData;
      }
    }
  }

  async getCheckTransitionDataById(salonId: number): Promise<any> {
    let url = this.contents_api_url + 'check_transition_by_id/' + salonId;
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['checktransition'];
      return pageData;
    }
  }

  async getAppVersion(): Promise<any> {
    let url = this.contents_api_url + 'app_version';
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return 'none';
    } else {
      const versionData = data['Pages']['appversion'];
      return versionData;
    }
  }

  async getPwaEnv(): Promise<any> {
    let url = this.contents_api_url + 'pwa_env';
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return 'none';
    } else {
      const pwaEnvData = data['Pages']['pwaenv'];
      return pwaEnvData;
    }
  }

  async getBuildDate(): Promise<any> {
    let url = this.contents_api_url + 'build_date';
    console.log(url);
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const buildDate = data['Pages']['builddate'];
      return buildDate;
    }
  }

  async getHomeDataForOG(salonName: string, isCompany: boolean): Promise<any> {
    let url = this.contents_api_url + 'home_for_og/' + salonName;
    if (isCompany) {
      url += '?h=1';
    } else {
      url += '?h=0';
    }
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['homeforog'];
      return pageData;
    }
  }

  async getCompanySalonDataById(salonId: number): Promise<any> {
    let url = this.contents_api_url + 'company_salon_data_by_id/' + salonId;
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const companySalonData = data['Pages']['companysalondata'];
      return companySalonData;
    }
  }

  async getCheckShopdetailRelSalon(salonName: string, shopDetailName: string): Promise<any> {
    let url = this.contents_api_url + 'check_shopdetail_rel_salon/' + salonName + '/' + shopDetailName;
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['checkshopdetailrelsalon'];
      return pageData;
    }
  }

  async getSalonFromOwnDomain(ownDomain: string): Promise<any> {
    let url = this.contents_api_url + 'salon_from_own_domain/' + ownDomain;
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['salon'];
      return pageData;
    }
  }

  async getOwnDomainList(): Promise<any> {
    let url = this.contents_api_url + 'own_domain_list';
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['owndomains'];
      return pageData;
    }
  }

  async getShortNameFromAccountName(accountName: string, isPreview: boolean): Promise<string> {
    let p_val = isPreview ? '1' : '0';
    let url = this.contents_api_url + 'short_name_from_account_name/' + accountName;
    const urlSearchParam = new URLSearchParams({ p: p_val }).toString();
    url = url + '/?' + urlSearchParam;
    const data = await this.http.get(url).toPromise().catch(null);
    if (data == null) {
      return null;
    } else {
      const pageData = data['Pages']['shortname'];
      return pageData;
    }
  }
}
