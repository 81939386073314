import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { StaffdetailPage } from './staffdetail.page';

import { FooterModule } from './../footer/footer.module';
import { HeaderModule } from './../header/header.module';
import { MobilefooterModule } from './../mobilefooter/mobilefooter.module';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PrivatePageModule } from './../private/private.module';
import { GallerymodalModule } from './../gallerymodal/gallerymodal.module';
import { MobilefooterbuttonsModule } from './../mobilefooterbuttons/mobilefooterbuttons.module';
import { PipesModule } from './../pipe/pipe.module';

const routes: Routes = [
  {
    path: '',
    component: StaffdetailPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    HeaderModule,
    MobilefooterModule,
    RouterModule.forChild(routes),
    LazyLoadImageModule,
    PrivatePageModule,
    GallerymodalModule,
    MobilefooterbuttonsModule,
    PipesModule
  ],
  declarations: [StaffdetailPage],
  exports: [StaffdetailPage]
})
export class StaffdetailPageModule {}
