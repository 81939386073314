import { Injectable } from '@angular/core';
import { LayoutData } from '../interfaces/layoutData.interface';
import { initLayoutData } from '../initData/initData';
import { PagedataService } from '../services/pagedata.service';

@Injectable()
export class GcsApiProvider {
  private doDebug: boolean = false;
  layoutData: LayoutData = initLayoutData;

  constructor(public pds: PagedataService) {
    this.load();
  }

  async load() {
    this.layoutData = await this.pds.getLayoutData();
    (<any>window).DirectReservationService._path = this.layoutData['ucsUrl'] + 'gcs/dwr';
  }

  getDwrCallback(callback) {
    (<any>window).dwr.engine.setErrorHandler(this.getErrorHandler(callback));
    (<any>window).dwr.engine.setWarningHandler(this.getWarningHandler(callback));
    (<any>window).dwr.engine.setTextHtmlHandler(this.getTextHtmlHandler(callback));
    let cb = (result) => {
      if (result.error == 'no app found') {
      }
      callback(result);
    };
    return {
      callback: cb,
      exceptionHandler: this.getErrorHandler(callback)
    };
  }

  getErrorHandler(callback) {
    return (message, ex) => {
      if (message === 'No data received from server') {
        callback({
          error: {
            message: 'offline',
            ex: ex
          }
        });
      } else {
        callback({
          error: {
            message: message,
            ex: ex
          }
        });
      }
    };
  }

  getWarningHandler(callback) {
    return (message, ex) => {
      callback({
        error: {
          message: message,
          ex: ex
        }
      });
    };
  }

  getTextHtmlHandler = (callback) => {
    return () => {
      callback({ error: 'textHtmlError' });
    };
  };

  offLineDebug(callback): boolean {
    if (!this.doDebug) {
      return false;
    } else if (Math.random() > 0.5) {
      // デバッグモードでは、半分の割合でオフラインエラーをエミュレーションさせる
      console.log('offline');
      callback({
        error: {
          message: 'offline',
          ex: ''
        }
      });
      return true;
    } else {
      return false;
    }
  }

  testGcs(callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findReservationsV1(this.getDwrCallback(callback));
    }
  }

  testGcs2(salonAccountName: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findSalonSettingV1(salonAccountName, this.getDwrCallback(callback));
    }
  }

  registerApp(
    salonAccountName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerAppV1(
        salonAccountName,
        modelName,
        platform,
        deviceUuid,
        osVersion,
        appVersion,
        appType,
        this.getDwrCallback(callback)
      );
    }
  }

  updateApp(
    token: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.updateAppV1(
        token,
        modelName,
        platform,
        deviceUuid,
        osVersion,
        appVersion,
        this.getDwrCallback(callback)
      );
    }
  }

  findTempTokenByToken(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findTempTokenByTokenV1(token, this.getDwrCallback(callback));
    }
  }

  findMyOwnerCredentialData(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findMyOwnerCredentialDataV1(token, this.getDwrCallback(callback));
    }
  }

  removeLinkMyOwnerCredential(token: string, linkName: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.removeLinkMyOwnerCredentialV1(
        token,
        linkName,
        this.getDwrCallback(callback)
      );
    }
  }

  findPWAReservationAppSalonData(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findPWAReservationAppSalonDataV1(token, this.getDwrCallback(callback));
    }
  }

  findMails(
    token: string,
    fromIndex: number,
    toIndex: number,
    messageType: string,
    salonId: number,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findMailsV1(
        token,
        fromIndex,
        toIndex,
        messageType,
        salonId,
        this.getDwrCallback(callback)
      );
    }
  }

  findOwnerCredentialByKey(key: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findOwnerCredentialByKeyV1(key, this.getDwrCallback(callback));
    }
  }

  findOwnerCredentialByAffiliateNumber(key: string, affiliateNumber: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findOwnerCredentialByAffiliateNumberV1(
        key,
        affiliateNumber,
        this.getDwrCallback(callback)
      );
    }
  }

  updatePushToken(
    token: string,
    iosPushToken: string,
    androidPushToken: string,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.updatePushTokenV2(
        token,
        iosPushToken,
        androidPushToken,
        this.getDwrCallback(callback)
      );
    }
  }

  findNotificationStatus(
    salonAccountName: string,
    token: string,
    isEnterprise: boolean,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findNotificationStatusV2(
        salonAccountName,
        token,
        isEnterprise,
        this.getDwrCallback(callback)
      );
    }
  }

  registerOrUpdateLineNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerOrUpdateLineNotificationV2(
        salonAccountName,
        token,
        isRegistered,
        isEnterprise,
        this.getDwrCallback(callback)
      );
    }
  }

  registerOrUpdateMailNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerOrUpdateMailNotificationV2(
        salonAccountName,
        token,
        isRegistered,
        isEnterprise,
        this.getDwrCallback(callback)
      );
    }
  }

  registerPwaCustomerMailAddress(
    salonAccountName: string,
    customerMailAddress: string,
    restorePreviousAddress: boolean,
    token: string,
    isEnterprise: boolean,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerPwaCustomerMailAddressV2(
        salonAccountName,
        customerMailAddress,
        restorePreviousAddress,
        token,
        isEnterprise,
        this.getDwrCallback(callback)
      );
    }
  }

  registerOrUpdatePwaNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerOrUpdatePwaNotificationV2(
        salonAccountName,
        token,
        isRegistered,
        isEnterprise,
        this.getDwrCallback(callback)
      );
    }
  }

  registerCustomerUniqueNumber(token: string, salonAccountName: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerCustomerUniqueNumberV1(
        token,
        salonAccountName,
        this.getDwrCallback(callback)
      );
    }
  }

  findSalonList(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findSalonListV1(token, this.getDwrCallback(callback));
    }
  }

  findSalonSetting(salonAccountName: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findSalonSettingV1(salonAccountName, this.getDwrCallback(callback));
    }
  }

  executeSendSupportForm(
    token: string,
    salonAccountName: string,
    content: string,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.executeSendSupportFormV1(
        token,
        salonAccountName,
        content,
        this.getDwrCallback(callback)
      );
    }
  }

  removeMyCustomerData(token: string, salonId: number, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.removeMyCustomerDataV1(token, salonId, this.getDwrCallback(callback));
    }
  }

  findLastReservation(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findLastReservationV1(token, this.getDwrCallback(callback));
    }
  }

  findPwaData(token: string, callback: (result: any) => void): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.findPwaDataV1(token, this.getDwrCallback(callback));
    }
  }

  registerEnterpriseApp(
    companyName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.registerEnterpriseAppV1(
        companyName,
        modelName,
        platform,
        deviceUuid,
        osVersion,
        appVersion,
        appType,
        this.getDwrCallback(callback)
      );
    }
  }

  addSalonFromEnterprise(
    token: string,
    companyName: string,
    customerId: number,
    callback: (result: any) => void
  ): void {
    if (!this.offLineDebug(callback)) {
      (<any>window).DirectReservationService.addSalonFromEnterpriseV1(
        token,
        companyName,
        customerId,
        this.getDwrCallback(callback)
      );
    }
  }
}
