<ion-header id="modalHeader">
  <ion-toolbar>
    <ion-buttons slot="start"></ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="close-btn" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="iframe-content" [scrollY]="false">
  <iframe class="iframe" [src]="safeUrl"></iframe>
</ion-content>
