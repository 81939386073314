import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MenuPage } from './menu.page';

import { FooterModule } from './../footer/footer.module';
import { HeaderModule } from './../header/header.module';
import { MobilefooterModule } from './../mobilefooter/mobilefooter.module';
import { PrivatePageModule } from './../private/private.module';
import { MobilefooterbuttonsModule } from './../mobilefooterbuttons/mobilefooterbuttons.module';
import { PipesModule } from './../pipe/pipe.module';
import { BlockComponentModule } from './../block/block.module';

const routes: Routes = [
  {
    path: '',
    component: MenuPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    HeaderModule,
    MobilefooterModule,
    RouterModule.forChild(routes),
    PrivatePageModule,
    MobilefooterbuttonsModule,
    PipesModule,
    BlockComponentModule
  ],
  declarations: [MenuPage],
  exports: [MenuPage]
})
export class MenuPageModule {}
