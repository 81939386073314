import { Injectable } from '@angular/core';
import { LayoutData } from '../interfaces/layoutData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initLayoutData, initSalonData } from '../initData/initData';
type Gtag = typeof gtag;
type WindowWithGtag = Window & { gtag: Gtag };

@Injectable({
  providedIn: 'root'
})
export class UpdateGoogleAnalyticsService {
  private windowWithGtag!: WindowWithGtag;
  private layoutData: LayoutData = initLayoutData;
  private salonData: SalonData = initSalonData;
  private shopDetailLayoutData: LayoutData = initLayoutData;
  private browserGlobals = {
    windowRef(): any {
      return window;
    }
  };

  constructor() {
    this.windowWithGtag = window as WindowWithGtag;
  }

  setLayoutData(data: LayoutData) {
    this.layoutData = data;
  }

  setSalonData(data: SalonData) {
    this.salonData = data;
  }

  setShopLayoutData(data: LayoutData) {
    this.shopDetailLayoutData = data;
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  // ページの行き来で毎回発生する計上処理
  accruePageviews(
    path: string,
    isUseShopDetailData: boolean,
    forcedPageName: string = null,
    forcedSalonName: string = null
  ) {
    let layoutData;
    if (isUseShopDetailData) {
      layoutData = this.shopDetailLayoutData;
    } else {
      layoutData = this.layoutData;
    }
    const ga4Tag = this.layoutData.gaTrackingGa4Tag;
    const isCompanyEnterprise = this.salonData.appEnabled === 5;
    if (ga4Tag || this.layoutData.gtmContainerId !== null) {
      var pageName;
      if (forcedPageName === null) {
        var key;
        if (path.indexOf('?') !== -1) {
          key = path.substring(0, path.indexOf('?'));
        } else {
          key = path;
        }
        var layoutDataPageName = layoutData['pathNameMap'][key];
        pageName = (<any>layoutData)[layoutDataPageName];
        if (typeof pageName === 'undefined') {
          // アドバンスのページ名はエンタープライズ（本部）から取得できないため、パス名で表示する
          pageName = path;
        }
      } else {
        pageName = forcedPageName;
      }
      if (this.layoutData.gaAppendSalonName || isCompanyEnterprise) {
        if (forcedSalonName === null || forcedSalonName === '') {
          let salonName = this.salonData.name;
          pageName = salonName + ' - ' + pageName;
        } else {
          pageName = forcedSalonName + ' - ' + pageName;
        }
      }
      if (this.layoutData.gtmContainerId !== null && this.layoutData.gtmContainerId !== '') {
        const gtmTag = {
          title: pageName,
          page: path,
          event: 'pwa-page-transition'
        };
        this.pushOnDataLayer(gtmTag);
      } else if (ga4Tag) {
        this.windowWithGtag.gtag('event', 'page_view', {
          page_title: pageName,
          page_path: path,
          page_location: window.location.href
        });
      }
    }
  }

  // 最初の1回だけ実施される計上処理（app.component.tsから呼び出し）
  createGTMPageview(pageName: string, key: string) {
    // 最初のアクセスがページビューで計上されないので、ここで計上する
    const gtmTag = {
      title: pageName,
      page: key,
      event: 'pwa-page-transition'
    };
    this.pushOnDataLayer(gtmTag);
  }
}
