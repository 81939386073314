<svg
  class="nav_shared_image_svg"
  [class.__modalStyle__]="isShopDetailNav"
  version="1.0"
  id="レイヤー_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="57px"
  height="36px"
  viewBox="0 0 57 36"
  enable-background="new 0 0 57 36"
  xml:space="preserve"
>
  <g class="nav_shared_image">
    <path
      class="nav_shared_image_1"
      d="M36.032,32.909l-0.273,2.225l-21.14-2.599l2.062-16.773l4.012,0.493l-0.526,1.447l-2.18-0.268l-1.696,13.795
               l18.161,2.233l0.132-1.074L36.032,32.909z"
    />
    <path
      class="nav_shared_image_1"
      d="M34.365,14.551l-1.885-0.679l0.278-1.494l1.885,0.679L34.365,14.551z M29.62,12.843l-4.254-1.532
               l-4.711,13.077l17.217,6.201l4.711-13.077l-5.359-1.93l0.278-1.494l7.001,2.522l-5.729,15.899L18.735,25.29L24.464,9.39l5.434,1.958
               L29.62,12.843z"
    />
    <path
      class="nav_shared_image_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      d="M35.556,16.349
               c-0.278,1.495-1.607,2.514-2.952,2.263c-1.345-0.25-2.219-1.678-1.941-3.173l1.011-5.437c0.278-1.495,1.606-2.514,2.953-2.264
               c1.346,0.25,2.218,1.678,1.941,3.173L35.556,16.349z"
    />
  </g>

  <g class="nav_shared_image_active">
    <g>
      <path
        class="nav_shared_image_active_1"
        d="M17.145,26.113l18.888,6.797l-0.273,2.224l-21.141-2.598l2.062-16.774l4.012,0.493L17.145,26.113z"
      />
    </g>
    <path
      class="nav_shared_image_active_1"
      d="M37.502,14.087l-0.472,2.536c-0.202,1.088-0.784,2.048-1.639,2.702c-0.7,0.537-1.543,0.823-2.387,0.823
                      c-0.227,0-0.453-0.021-0.677-0.062c-1.058-0.196-2-0.856-2.584-1.812c-0.562-0.917-0.759-2.022-0.557-3.11l0.71-3.817l0,0
                      L24.464,9.39L18.735,25.29l20.039,7.219l5.728-15.899L37.502,14.087L37.502,14.087z M32.757,12.378l-0.62,3.336
                      c-0.065,0.353-0.005,0.716,0.167,0.996c0.082,0.135,0.267,0.37,0.574,0.428c0.3,0.057,0.562-0.096,0.689-0.192
                      c0.261-0.2,0.448-0.518,0.514-0.87l0.561-3.018l0,0L32.757,12.378L32.757,12.378z"
    />
    <path
      class="nav_shared_image_active_2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      d="M35.555,16.35
                      c-0.278,1.495-1.607,2.514-2.952,2.263c-1.345-0.25-2.219-1.678-1.941-3.173l1.011-5.437c0.278-1.495,1.606-2.514,2.953-2.264
                      c1.346,0.25,2.218,1.678,1.941,3.173L35.555,16.35z"
    />
  </g>
</svg>
