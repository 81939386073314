<div class="modal_filter" [class.__modalStyle__]="isShopDetailNav"></div>
<div class="galleryModal_container modal_container">
  <!-- 閉じるボタン -->
  <div class="modal__closeBtnBody">
    <span
      (click)="closeModal(modalid)"
      class="galleryModal__closeBtnLink modal__closeBtnLink"
      [class.__modalStyle__]="isShopDetailNav"
    ></span>
  </div>
  <div class="galleryModal__content modal__content" id="{{ modalid }}" [class.__modalStyle__]="isShopDetailNav">
    <!-- ギャラリーモーダル -->
    <div class="modal__content_container">
      <!-- 通常のギャラリーの場合 -->
      <ng-container *ngIf="!selectedInfo.instagramFlg; else elseContent">
        <!-- スライダー -->
        <div class="galleryModal__content__slider modal__content__slider">
          <!-- スライダー 画像コンテナ -->
          <div class="galleryModal__content__slider__imgBox modal__content__slider__imgBox">
            <!-- スライダー 画像 -->
            <div class="modal__content__slider__photos">
              <ng-container *ngFor="let c of selectedModal; index as i">
                <div
                  *ngIf="i == 0; else isShow"
                  id="gallery_paneImage_{{ c.id }}"
                  class="item galleryModal__content__slider__photo modal__content__slider__photo show"
                  (touchstart)="touchstart($event)"
                  (touchmove)="touchmove($event)"
                  (touchend)="touchend($event)"
                >
                  <picture>
                    <source *ngIf="c.webpUrl != null" type="image/webp" srcset="{{ c.webpUrl }}" />
                    <img src="{{ c.url }}" />
                  </picture>
                </div>
                <ng-template #isShow>
                  <div
                    id="gallery_paneImage_{{ c.id }}"
                    class="item galleryModal__content__slider__photo modal__content__slider__photo"
                    (touchstart)="touchstart($event)"
                    (touchmove)="touchmove($event)"
                    (touchend)="touchend($event)"
                  >
                    <picture>
                      <source *ngIf="c.webpUrl != null" type="image/webp" srcset="{{ c.webpUrl }}" />
                      <img src="{{ c.url }}" />
                    </picture>
                  </div>
                </ng-template>
              </ng-container>
            </div>

            <!-- スライダー 左アイコン -->
            <span
              (click)="backPane()"
              *ngIf="showBack"
              class="galleryModal__content__slider__left modal__content__slider__left"
              ><span><</span></span
            >

            <!-- スライダー 右アイコン -->
            <span
              (click)="nextPane()"
              *ngIf="showNext"
              class="galleryModal__content__slider__right modal__content__slider__right"
              ><span>></span></span
            >
          </div>

          <!-- スライダー ページネーション -->
          <div class="modal__content__slider__pagination" *ngIf="paneCount > 1">
            <span
              *ngFor="let c of selectedModal"
              class="modal__content__slider__pagination__btn"
              [class.current]="panePos == c.dispOrder"
              [class.__modalStyle__]="isShopDetailNav"
            ></span>
          </div>
          <!-- /.galleryModal__content__slider__pagination -->
        </div>
        <!-- /.galleryModal__content__slider -->

        <!-- 写真説明 -->
        <div class="galleryModal__content__heading modal__content__heading">
          <h2
            *ngIf="selectedInfo.title"
            class="galleryModal__content__heading__title modal__content__heading__title"
            [class.__modalStyle__]="isShopDetailNav"
          >
            {{ selectedInfo.title }}
          </h2>
          <p
            *ngIf="selectedInfo.concept"
            class="galleryModal__content__heading__desc modal__content__heading__desc"
            [class.__modalStyle__]="isShopDetailNav"
          >
            {{ selectedInfo.concept }}
          </p>
        </div>
        <!-- /.galleryModal__content__heading -->
      </ng-container>
      <!-- Instagramの場合 -->
      <ng-template #elseContent>
        <!-- Instagram埋め込み -->
        <div [innerHTML]="embedInstaUrl"></div>
      </ng-template>

      <ng-container
        *ngIf="
          selectedInfo.staffId != null &&
          selectedInfo.staff.isDisplay &&
          dispModalStaffDetail &&
          selectedInfo.staff.staffPageInfo.isDisplay
        "
      >
        <hr class="modal__content__separateLine" [class.__modalStyle__]="isShopDetailNav" />
        <!-- スタッフ -->
        <div class="galleryModal__content__staff modal__content__staff" [class.__modalStyle__]="isShopDetailNav">
          <h3
            class="galleryModal__content__staff__heading modal__content__staff__heading"
            [class.__modalStyle__]="isShopDetailNav"
          >
            担当スタッフ
          </h3>

          <div
            [ngClass]="{ staffContentNoPhoto: noPhoto }"
            class="galleryModal__content__staff__content modal__content__staff__content"
          >
            <!-- スタッフ 画像 -->
            <div
              *ngIf="selectedInfo.staff.photo"
              class="galleryModal__content__staff__content__imgBox modal__content__staff__content__imgBox"
            >
              <img
                src="{{ selectedInfo.staff.photo }}"
                class="galleryModal__content__staff__content__img modal__content__staff__content__img"
              />
            </div>
            <!-- /.galleryModal__content__staff__content__imgBox -->

            <!-- スタッフ情報 名前・役職 -->
            <div
              [ngClass]="{ staffInfoNoPhoto: noPhoto }"
              class="galleryModal__content__staff__content__staffInfo modal__content__staff__content__staffInfo"
            >
              <h4
                class="galleryModal__content__staff__content__staffInfo__name modal__content__staff__content__staffInfo__name"
                [class.__modalStyle__]="isShopDetailNav"
              >
                {{ selectedInfo.staff.name }}
              </h4>
              <h4
                class="galleryModal__content__staff__content__staffInfo__staffPosition modal__content__staff__content__staffInfo__staffPosition"
                [class.__modalStyle__]="isShopDetailNav"
              >
                {{ selectedInfo.staff.staffPosition }}
              </h4>
              <!-- ボタン -->
              <div class="galleryModal__content__staff__content__staffInfo__btns modal__content__btns">
                <span
                  *ngIf="
                    selectedInfo.staff.isDisplayReserveLink &&
                    selectedInfo.staff.reserveDealType != 1 &&
                    !selectedInfo.staff.invalidPublic
                  "
                  (click)="clickBooking(selectedInfo.staff)"
                  class="galleryModal__content__staff__content__staffInfo__btns__bookShimei modal__content__btns__bookShimei btn_bookShimei"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  指名予約する
                </span>
                <span
                  class="galleryModal__content__staff__content__staffInfo__btns__checkDetail modal__content__btns__checkDetail btn_checkDetail"
                  [class.__modalStyle__]="isShopDetailNav"
                  (click)="
                    clickLink(selectedInfo.staff.salonInfo.accountName, 'staffdetail', {
                      s: salonName,
                      staff_id: selectedInfo.staff.id,
                      p: isPreview,
                      key: paramKey
                    })
                  "
                  >詳細を見る</span
                >
              </div>
            </div>
            <!-- /.galleryModal__content__staff__content__staffInfo -->
          </div>
          <!-- /.galleryModal__content__staff__content -->
        </div>
        <!-- /.galleryModal__content__staff -->
      </ng-container>

      <!-- スタッフ所属店舗 -->
      <ng-container
        *ngIf="
          isCompany &&
          !isShopDetailNav &&
          selectedInfo.staffId != null &&
          selectedInfo.staff.isDisplay &&
          dispModalStaffDetail &&
          selectedInfo.staff.salonPageData.isDisplay &&
          selectedInfo.staff.staffPageInfo.isDisplay
        "
      >
        <div
          class="galleryModal__content__staffShop modal__content__staffShop"
          [class.__modalStyle__]="isShopDetailNav"
        >
          <div class="galleryModal__content__staffShop__shopName modal__content__staffShop__shopName">
            <h3
              class="galleryModal__content__staffShop__shopName__heading modal__content__staffShop__shopName__heading"
              [class.__modalStyle__]="isShopDetailNav"
            >
              {{ selectedInfo.staff.salonInfo.name }}
            </h3>
          </div>
          <div
            *ngIf="selectedInfo.staff.isShopPhoto || selectedInfo.staff.isShopInfo"
            class="galleryModal__content__staffShop__body modal__content__staffShop__body"
            [class.onlyShopPhoto]="!selectedInfo.staff.isShopInfo && selectedInfo.staff.isShopPhoto"
            [class.onlyShopInfo]="selectedInfo.staff.isShopInfo && !selectedInfo.staff.isShopPhoto"
          >
            <div
              class="galleryModal__content__staffShop__body__visual modal__content__staffShop__body__visual"
              *ngIf="selectedInfo.staff.isShopPhoto"
            >
              <div>
                <div
                  class="galleryModal__content__staffShop__body__visual__square modal__content__staffShop__body__visual__square"
                >
                  <img
                    src="{{ selectedInfo.staff.preferenceData.shopPhoto }}"
                    class="galleryModal__content__staffShop__body__visual__img modal__content__staffShop__body__visual__img"
                  />
                </div>
              </div>
            </div>
            <div
              class="galleryModal__content__staffShop__body__info modal__content__staffShop__body__info"
              *ngIf="selectedInfo.staff.isShopInfo"
            >
              <div
                class="galleryModal__content__staffShop__body__info__textArea modal__content__staffShop__body__info__textArea"
              >
                <div
                  class="galleryModal__content__staffShop__body__info__textContent modal__content__staffShop__body__info__textContent"
                  *ngIf="selectedInfo.staff.salonPageData.address != ''"
                >
                  <ion-icon
                    name="location-sharp"
                    class="galleryModal__content__staffShop__body__info__textContent__icon modal__content__staffShop__body__info__textContent__icon"
                  ></ion-icon>
                  <div
                    class="galleryModal__content__staffShop__body__info__textContent__body modal__content__staffShop__body__info__textContent__body"
                  >
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__text modal__content__staffShop__body__info__textContent__body__text"
                      [innerHTML]="selectedInfo.staff.salonPageData.address"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                  </div>
                </div>
                <div
                  class="galleryModal__content__staffShop__body__info__textContent modal__content__staffShop__body__info__textContent"
                  *ngIf="selectedInfo.staff.salonPageData.access != ''"
                >
                  <ion-icon
                    src="assets/images/icons/access-arrow.svg"
                    class="galleryModal__content__staffShop__body__info__textContent__icon modal__content__staffShop__body__info__textContent__icon"
                  ></ion-icon>
                  <div
                    class="galleryModal__content__staffShop__body__info__textContent__body modal__content__staffShop__body__info__textContent__body"
                  >
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__text modal__content__staffShop__body__info__textContent__body__text"
                      [innerHTML]="selectedInfo.staff.salonPageData.access"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                  </div>
                </div>
                <div
                  class="galleryModal__content__staffShop__body__info__textContent modal__content__staffShop__body__info__textContent"
                  *ngIf="
                    selectedInfo.staff.salonPageData.businessHours != '' ||
                    selectedInfo.staff.salonPageData.holiday != ''
                  "
                >
                  <ion-icon
                    name="time-outline"
                    class="galleryModal__content__staffShop__body__info__textContent__icon modal__content__staffShop__body__info__textContent__icon"
                  ></ion-icon>
                  <div
                    class="galleryModal__content__staffShop__body__info__textContent__body modal__content__staffShop__body__info__textContent__body"
                  >
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__title modal__content__staffShop__body__info__textContent__body__title"
                      *ngIf="selectedInfo.staff.salonPageData.businessHours != ''"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      【営業時間】
                    </p>
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__text modal__content__staffShop__body__info__textContent__body__text"
                      [innerHTML]="selectedInfo.staff.salonPageData.businessHours"
                      *ngIf="selectedInfo.staff.salonPageData.businessHours != ''"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__title modal__content__staffShop__body__info__textContent__body__title"
                      *ngIf="selectedInfo.staff.salonPageData.holiday != ''"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      【定休日】
                    </p>
                    <p
                      class="galleryModal__content__staffShop__body__info__textContent__body__text modal__content__staffShop__body__info__textContent__body__text"
                      [innerHTML]="selectedInfo.staff.salonPageData.holiday"
                      *ngIf="selectedInfo.staff.salonPageData.holiday != ''"
                      [class.__modalStyle__]="isShopDetailNav"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.galleryModal__content__staffShop -->

        <!-- ボタン -->
        <div
          *ngIf="
            isCompany &&
            !isShopDetailNav &&
            selectedInfo.staffId != null &&
            selectedInfo.staff.isDisplay &&
            dispModalStaffDetail
          "
          class="galleryModal__content__btns modal__content__btns"
        >
          <span
            class="galleryModal__content__btns__checkDetail modal__content__btns__checkDetail btn_checkDetail"
            [class.__modalStyle__]="isShopDetailNav"
            (click)="clickLink(selectedInfo.staff.salonInfo.accountName, 'shop')"
            >もっとみる</span
          >
        </div>
        <!-- /.galleryModal__content__btns -->
      </ng-container>
    </div>
    <!-- /.modal__content_container -->
  </div>
  <!-- /.galleryModal__content -->
</div>
<!-- /.galleryModal_container -->
<span>{{ loadEmbeds() }}</span>
