import { Injectable } from '@angular/core';
import { firebase } from '@firebase/app';
import '@firebase/messaging';
import { firebaseConfig } from '../../firebase-config';
import { PagedataService } from './pagedata.service';
import { LocalStorageData } from '../pagedata';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { ReservationServiceProvider } from './ReservationServiceProvider';
import { RequestParamsService } from './requestParams.service';
import { AlertController, ModalController } from '@ionic/angular';
import { LineNotificationModalComponent } from '../line-notification-modal/line-notification-modal.component';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { AccountService } from './account.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // パーミッションステータス
  static permissionStatus = {
    default: 'default',
    granted: 'granted',
    denied: 'denied'
  };
  constructor(
    public pds: PagedataService,
    public rs: ReservationServiceProvider,
    private requestParamsService: RequestParamsService,
    public alertController: AlertController,
    private modalController: ModalController,
    public accountService: AccountService
  ) {}

  public init(isProduction: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then(
        (registration) => {
          // Don't crash an error if messaging not supported
          if (!firebase.messaging.isSupported()) {
            resolve();
            return;
          }

          const messaging = firebase.messaging();

          // Register the Service Worker
          messaging.useServiceWorker(registration);

          // Initialize your VAPI key
          messaging.usePublicVapidKey(
            isProduction ? firebaseConfig.production.firebase.vapidKey : firebaseConfig.test.firebase.vapidKey
          );

          // Optional and not covered in the article
          // Listen to messages when your app is in the foreground
          messaging.onMessage((payload) => {
            console.log(payload);
          });
          // Optional and not covered in the article
          // Handle token refresh
          messaging.onTokenRefresh(() => {
            messaging
              .getToken()
              .then((refreshedToken: string) => {
                console.log(refreshedToken);
              })
              .catch((err) => {
                console.error(err);
              });
          });

          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  // NotificationAPIがサポートされているブラウザかチェック
  public isSupportedNotification = async () => {
    if (this.pds.getIsDebuggIOS16_3()) {
      return false;
    }
    return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
  };

  /**
   * 通知許可をユーザーに尋ねる
   * @returns True:許可ok
   */
  public async requestPermission(): Promise<boolean> {
    try {
      if (!Notification || !firebase.messaging.isSupported()) {
        return false;
      }

      const messaging = firebase.messaging();
      const resultPermission = await Notification.requestPermission();

      if (resultPermission !== 'granted') {
        // 許可しないとプッシュトークンを得られない
        return false;
      }

      const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
      const token = userData?.token || null;

      const pushToken: string = await messaging.getToken();
      if (pushToken === null) {
        console.log('pushToken invalid!!!');
        return false;
      }

      if (this.pds.getIsIos()) {
        await this.rs.updatePushToken(token, pushToken, null);
      } else if (this.pds.getIsAndroid()) {
        await this.rs.updatePushToken(token, null, pushToken);
      }

      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      const salonAccountName = accountData.accountName || null;
      await this.rs.registerOrUpdatePwaNotification(salonAccountName, token, true, this.pds.getIsCompany());
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public async openModalLineNotificationModal() {
    const modal = await this.modalController.create({
      component: LineNotificationModalComponent,
      cssClass: 'dialog-container line-notification-modal-height',
      backdropDismiss: false
    });

    // モーダルを閉じた時の処理
    let modalResult: boolean | null = null;
    modal.onDidDismiss().then((result: { data: boolean; role: string }) => {
      // 次へ:true
      modalResult = result.data;
    });

    await modal.present();
    await modal.onDidDismiss();
    return modalResult;
  }

  /**
   * プッシュトークンを登録する処理
   * True:登録成功 False:登録失敗
   */
  public async registerPushToken(lineNotificationEnabled: boolean): Promise<boolean> {
    try {
      const isProduction = this.pds.pwaEnv === 'production';
      const firebaseInfo = isProduction ? firebaseConfig.production.firebase : firebaseConfig.test.firebase;
      firebase.initializeApp(firebaseInfo);
      this.init(isProduction);

      if (Notification.permission === NotificationsService.permissionStatus.granted) {
        // 通知許可済み
        const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
        const salonAccountName = accountData.accountName !== null ? accountData.accountName : null;

        // プッシュトークンが登録できているか調べる
        const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
        const userToken = userData !== null ? userData.token : null;
        const pwa = await this.rs.findPwaData(userToken).catch((error) => {
          console.error(error);
          return false;
        });

        // 登録済み
        if (pwa.pushTokenSuccsess) {
          return true;
        }

        // プッシュトークンが登録できていない場合はダイアログを出す
        if (pwa.pushTokenSuccsess !== null && !pwa.pushTokenSuccsess) {
          const alertResult = await this.pushTokenUnRegisteredAlert(
            'プッシュ通知の登録が完了していません。「次へ」を押すとプッシュ通知の登録が完了します。'
          );
          if (alertResult) {
            // プッシュトークン登録
            const pushTokenResult = await this.requestPermission();
            if (pushTokenResult) {
              await this.pushTokenSuccess();
              return true;
            }
          }
        }
      } else if (this.getIsPermissionDefault()) {
        // 通知許可前
        let modalResult = null;
        if (lineNotificationEnabled) {
          modalResult = await this.openModalLineNotificationModal();
        } else {
          modalResult = await this.notificationConfirmAlert();
        }
        if (modalResult) {
          await this.requestPermission();
        }
      } else {
        // 通知を拒否
        console.error('permission denied');
      }
    } catch (error) {
      // 通知関連で何らかのエラーが発生したとき
      console.error(error);
    }

    return false;
  }

  // 通知許可を出す前のダイアログ
  public async notificationConfirmAlert(): Promise<boolean> {
    let result = false;

    // Androidだけ「あとで」ボタンをつける
    // iOS 16.4.1はプッシュ通知ステータスがおかしいので「あとで」を出さない
    const buttons: AlertButton[] = [];
    buttons.push({
      text: '次へ',
      handler: () => {
        result = true;
      }
    });
    if (this.pds.getIsAndroid()) {
      buttons.unshift({
        text: 'あとで',
        handler: () => {
          result = false;
          return true;
        }
      });
    }
    const alert = await this.alertController.create({
      cssClass: '',
      backdropDismiss: false,
      subHeader: '予約に関するお知らせを受け取りますか？',
      message: '次の画面で「許可」を押すと、予約受付のお知らせやサロンからの連絡など重要な情報を受け取れます。',
      buttons
    });
    await alert.present();
    await alert.onDidDismiss();
    return result;
  }

  // プッシュトークンが登録できていないダイアログ
  public async pushTokenUnRegisteredAlert(message: string): Promise<boolean> {
    let result = false;
    const alert = await this.alertController.create({
      cssClass: '',
      message,
      buttons: [
        {
          text: 'あとで',
          handler: () => {
            result = false;
            return true;
          }
        },
        {
          text: '次へ',
          handler: () => {
            result = true;
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
    return result;
  }

  // プッシュトークン登録成功
  public async pushTokenSuccess() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: '登録が成功しました',
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

  private getIsPermissionDefault() {
    // iOSのNotification.permissionは正しく取れないので、初回起動時のみtrueを返す
    const isPermissionDefault = Notification.permission === NotificationsService.permissionStatus.default;
    const pushDialogDisplayed = this.pds.getData(LocalStorageData.PUSH_DIALOG_DISPLAYED);
    if (this.pds.getIsIos()) {
      this.pds.setData(LocalStorageData.PUSH_DIALOG_DISPLAYED, true);
      return isPermissionDefault && !pushDialogDisplayed;
    } else if (this.pds.getIsAndroid()) {
      return isPermissionDefault;
    } else {
      return false;
    }
  }
}
