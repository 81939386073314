import { Injectable } from '@angular/core';
import { PagedataService } from './pagedata.service';
import { RequestParamsService } from './requestParams.service';
import { ReservationServiceProvider } from './ReservationServiceProvider';
import { ModalController } from '@ionic/angular';
import { AffiliateNumberComponent } from '../affiliatenumber/affiliatenumber.component';
import { Subject } from 'rxjs';
import { APP_ENABLED, LocalStorageData } from '../pagedata';
import { LayoutData } from '../interfaces/layoutData.interface';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { initLayoutData } from '../initData/initData';
import { HandShakeComponent } from '../handshake/handshake.component';
import { MetaDataController } from './metaDataController';
import { MessageComponent } from '../message/message.component';
import { WindowService } from './windowService';
import { AccountService } from './account.service';
import { ShopDetailModalService } from './shopDetailModal.service';
import { SalonData } from '../interfaces/salonData.interface';
declare let ga: any;

@Injectable({
  providedIn: 'root'
})
export class MyPageService {
  public static RESERVATION = 'reservation';
  public static RESERVATION_LIST = 'reservationList';
  public static SHARED_IMAGES = 'sharedImages';
  public static MESSAGE_LIST = 'messageList';
  public static POINT = 'point';
  public static HAND_SHAKE = 'handShake';
  public static NOTIFICATION_SETTING = 'notificationSetting';
  public static MAP = 'map';

  private badgeSubject = new Subject<number>();
  public badgeState = this.badgeSubject.asObservable();

  // 認証番号入力
  private affiliationModalSubject = new Subject<string>();
  public affiliationModalSubject$ = this.affiliationModalSubject.asObservable();
  layoutData: LayoutData = initLayoutData;
  // プッシュ通知タップかどうかフラグ
  private _isPushTap: boolean = false;

  private salonData: SalonData;
  private isAdvance: boolean;

  constructor(
    public pds: PagedataService,
    private requestParamsService: RequestParamsService,
    public rs: ReservationServiceProvider,
    private modalController: ModalController,
    public metadatacontroller: MetaDataController,
    private windowService: WindowService,
    public accountService: AccountService,
    public shopDetailModalService: ShopDetailModalService
  ) {}

  set isPushTap(isPushTap: boolean) {
    this._isPushTap = isPushTap;
  }

  // Googleアナリティクス（タグマネージャー）クロスドメイン用のパラメータを取得（"_ga=XXXX"形式）
  public getCrossdomainLinker() {
    if (typeof window['ga'] !== 'undefined') {
      var firstTracker = ga.getAll()[0];
      if (firstTracker) {
        return firstTracker.get('linkerParam');
      }
    }
  }

  // urlのkeyが有効かチェック
  public async authenticateKey(pageName: string, param: ParamForReservation) {
    const params = this.requestParamsService.getRequestParams();
    if (params.key != null) {
      const key = params.key;
      const result = await this.rs.findOwnerCredentialByKey(key).catch((error) => {
        return error;
      });

      // keyが無効、有効期限切れ
      if (result.error) {
        return await this.openModal(pageName, true, param);
      } else if (result.message == 'no authentication required') {
        // key有効　認証番号不要
        const token: string = result.token;
        return await this.transitionReservationPageTo(pageName, token, param);
      } else if (result.message == 'authentication required') {
        // key有効　認証番号必要
        return await this.openModal(pageName, false, param);
      }
    } else {
      // アプリでの操作

      // メッセージはモーダルで表示するためwindow.openしない
      if (pageName !== MyPageService.MESSAGE_LIST && !this.isLineWebBrowser()) {
        this.windowService.setWindowReference(window.open());
      }
      return await this.transitionReservationPageTo(pageName, null, param);
    }
  }

  // LINEのWebViewかどうか判定
  private isLineWebBrowser = () => {
    const userAgent = navigator.userAgent;
    const isLineWebOpen = /Line/i.test(userAgent);
    return isLineWebOpen;
  };

  // 認証番号入力画面を表示する
  public async openModal(pageName: string, isExpiredKey: boolean, param: ParamForReservation) {
    const modal = await this.modalController.create({
      component: AffiliateNumberComponent,
      cssClass: 'dialog-container affiliate-number-component-height',
      backdropDismiss: false,
      componentProps: {
        pageName: pageName,
        isExpiredKey: isExpiredKey
      }
    });

    // モーダルを閉じた時の処理
    modal.onDidDismiss().then((result) => {
      if (result != null && result.data != null) {
        // 認証がokでtempTokenが返ってきたら利用する
        const tempToken: string = result.data.tempToken;
        const token: string = result.data.token;
        if (pageName === MyPageService.NOTIFICATION_SETTING) {
          // 通知設定を開く
          this.affiliationModalSubject.next(token);
        } else {
          this.transitionReservationPageTo(pageName, token, param);
        }
      } else {
        // 閉じる、スキップなどでモーダルを閉じた場合
        if (pageName === MyPageService.NOTIFICATION_SETTING) {
          // 通知設定
          return null;
        } else {
          this.transitionReservationPageTo(pageName, null, param);
        }
      }
    });

    return await modal.present();
  }

  // ハンドシェイクの画面を出す
  public async openModalHandshake(viewReceiptReservationApps: boolean) {
    const modal = await this.modalController.create({
      component: HandShakeComponent,
      cssClass: 'dialog-container handshake-component-height',
      backdropDismiss: true,
      componentProps: {
        viewReceiptReservationApps: viewReceiptReservationApps
      }
    });

    // モーダルを閉じた時の処理
    modal.onDidDismiss().then((result) => {});

    return await modal.present();
  }

  /**
   * 今すぐ予約の画面へ遷移する
   * @param pageName 遷移するページ
   * @param token スタンドアロンで起動する場合はnull
   */
  public async transitionReservationPageTo(pageName: string, token: string, param: ParamForReservation) {
    const urlParams = this.requestParamsService.getRequestParams();
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.layoutData = await this.pds.getLayoutData();
    const gcsUrlBase = this.layoutData['ucsUrl'];
    const gaid = this.getCrossdomainLinker() != undefined ? this.getCrossdomainLinker() : null;

    let url = '';
    if (this.pds.getIsStandAloneMode()) {
      // アプリ起動 or URLログイン

      if (urlParams.key != null) {
        // URLログイン
        const salonName = param.salonName ? param.salonName : accountData.accountName;
        if (token != null) {
          const result = await this.rs.findTempTokenByToken(token).catch((error) => {
            return error;
          });
          if (result.error) {
            console.log('findTempTokenByToken ERROR!', result.error);
            return;
          }
          const tempToken: string = result.tempToken;
          if (pageName === MyPageService.MESSAGE_LIST) {
            // メッセージが開かれたら未読数を更新する
            await this.findUnreadMessageCount(token);
          }
          url = this.createUrl(salonName, pageName, tempToken, gaid, param);
        } else if (pageName === MyPageService.RESERVATION) {
          url = this.createUrlForReserve(salonName, param.staffId, gaid);
        } else {
          // 認証番号スキップで新規予約以外は何も処理しない
          return;
        }
      } else {
        // アプリで起動している時
        const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
        const token: string = userData != null ? userData.token : null;
        if (pageName === MyPageService.MESSAGE_LIST) {
          // メッセージが開かれたら未読数を更新する
          await this.findUnreadMessageCount(token);
        }

        const result = await this.rs.findTempTokenByToken(token).catch((error) => {
          return error;
        });
        if (result.error) {
          console.log('findTempTokenByToken ERROR!', result.error);
          return;
        }
        const tempToken: string = result.tempToken;

        // 店舗詳細を開いている場合は店舗名を使用
        let salonOrCompanyName = '';
        if (this.shopDetailModalService.isShopDetailNav) {
          salonOrCompanyName = param.salonName;
        } else {
          this.salonData = await this.pds.getSalonData();
          // ポイント画面は店舗版と同じにする
          this.isAdvance =
            this.salonData['appEnabled'] === APP_ENABLED.PWA_APP_ADVANCE &&
            this.salonData['companyId'] != null &&
            pageName !== MyPageService.POINT;
          if (pageName === MyPageService.RESERVATION) {
            // 直接予約へ進むときは店舗指定
            salonOrCompanyName = param.salonName ? param.salonName : accountData.accountName;
          } else {
            if (this.isAdvance) {
              salonOrCompanyName = this.salonData.companyName;
            } else {
              salonOrCompanyName = accountData.accountName;
            }
          }
        }

        url = this.createUrl(salonOrCompanyName, pageName, tempToken, gaid, param);
        if (pageName === MyPageService.MESSAGE_LIST) {
          this.openModalMessage(url, token);
          return;
        }
      }
    } else {
      // ブラウザ版
      if (pageName === MyPageService.RESERVATION) {
        // 通常の今すぐ予約画面へ
        const salonName = param.salonName ? param.salonName : accountData.accountName;
        url = this.createUrlForReserve(salonName, param.staffId, gaid);
      } else if (pageName === MyPageService.MAP) {
        // map画面へ
        const companyName = accountData.accountName;
        url = this.layoutData['ucsUrl'] + companyName + '/map';
      } else {
        // ブラウザ版PWAはURLログインでしか予約以外の処理を想定していない
        return;
      }
    }

    // プッシュ通知タップ
    if (this._isPushTap) {
      this.windowService.openWindow(url);
      return;
    }

    // safariではデフォルトでポップアップをブロックしている
    this.windowService.openWindow(url);
  }

  // 遷移用のURLを作成する
  private createUrl(
    salonOrCompanyName: string,
    pageName: string,
    tempToken: string,
    gaid: string,
    param: ParamForReservation
  ): string {
    let url: string = `${this.layoutData.ucsUrl}gcs/user/userLoginAccount.do?salonAccount=${salonOrCompanyName}&pageName=${pageName}&tempToken=${tempToken}`;
    if (pageName === MyPageService.RESERVATION && param.staffId > 0) {
      url += `&staffId=${param.staffId}`;
    }
    if (gaid != null && gaid.length > 0) {
      url += `&${gaid}`;
    }
    if (param.lastReservation === 1) {
      url += `&lastReservation=1`;
    }
    if (this.metadatacontroller.getGaClientId() !== null && this.metadatacontroller.getGaSessionId() !== null) {
      url += `&gaClientId=${this.metadatacontroller.getGaClientId()}&gaSessionId=${this.metadatacontroller.getGaSessionId()}`;
    }
    // エンタープライズ+アドバンスでもエンタープライズと同様
    if (
      !this.shopDetailModalService.isShopDetailNav &&
      (this.pds.getIsCompany() || this.isAdvance) &&
      pageName !== MyPageService.RESERVATION
    ) {
      url += '&isCompany=true';
    }
    return url;
  }

  // 通常の今すぐ予約画面遷移用のURLを作成する
  private createUrlForReserve(salonName: string, staffId: number, gaid: string): string {
    let baseUrl = `${this.layoutData.ucsUrl}${salonName}`;
    const params = [];
    if (staffId > 0) {
      baseUrl += `/staff${staffId}`;
    } else {
      baseUrl += '/r';
    }
    if (gaid != null && gaid.length > 0) {
      params.push(`${gaid}`);
    }
    if (this.metadatacontroller.getGaClientId() !== null && this.metadatacontroller.getGaSessionId() !== null) {
      params.push(`gaClientId=${this.metadatacontroller.getGaClientId()}`);
      params.push(`gaSessionId=${this.metadatacontroller.getGaSessionId()}`);
    }
    for (let i = 0; i < params.length; i++) {
      let symbol = i === 0 ? '?' : '&';
      baseUrl += symbol + params[i];
    }
    return baseUrl;
  }

  /**
   * 未読メッセージ数を取得してローカルストレージに保存する
   * @param token スタンドアロンで起動する場合はnull
   */
  public async findUnreadMessageCount(token: string): Promise<void> {
    // 引数のtokenが指定されていない場合はストレージから読み込む
    if (!token) {
      const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
      token = !!userData ? userData.token : null;
      if (!token) {
        console.log('no token!!!');
        return;
      }
    }

    // 対象のサロンのメッセージだけ取得
    const salonData = await this.pds.getSalonData();
    const salonId = salonData.csSalonId;
    return new Promise<void>((resolve, reject) => {
      this.rs
        .findMails(token, 0, 5, 'unread', salonId)
        .then((result) => {
          let unreadCount = 0;
          if (this.pds.getIsCompany()) {
            unreadCount = result?.allUnreadCount ?? 0;
          } else {
            unreadCount = result?.unreadCount ?? 0;
          }
          this.pds.setData(LocalStorageData.MESSAGE_COUNT, { unreadCount: unreadCount }); // TODO:保存しなくていいかも
          // 未読数バッジを更新イベント発火
          this.badgeSubject.next(unreadCount);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  public async openModalMessage(url: string, token: string) {
    let deviceClass = this.pds.getIsIos() ? '__Ios__' : '__noIos__';
    // モーダルの背景要素を設定する（shopDetailModalが取得できない場合は、ion-pageを取得する）
    let presentingElement: HTMLElement;
    presentingElement = document.querySelector('#shopDetailModal');
    if (presentingElement === null) {
      presentingElement = document.querySelector('#ion-page');
    }

    const modal = await this.modalController.create({
      component: MessageComponent,
      cssClass: ['card-modal', deviceClass],
      componentProps: { url: url, token: token },
      backdropDismiss: true,
      presentingElement: presentingElement
    });

    // モーダルを閉じた時の処理
    let modalResult: boolean | null = null;
    modal.onDidDismiss().then((result: { data: boolean; role: string }) => {
      // 次へ:true
      modalResult = result.data;
      this.findUnreadMessageCount(token);
    });

    await modal.present();
    await modal.onDidDismiss();
    return modalResult;
  }
}
