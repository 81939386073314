import { Injectable } from '@angular/core';
import { GcsApiProvider } from './GcsApiProvider';
import { ReservationServiceProvider } from './ReservationServiceProvider';

@Injectable()
export class GcsServiceProvider extends ReservationServiceProvider {
  constructor(private api: GcsApiProvider) {
    super();
  }

  testGcs(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.testGcs((result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  testGcs2(salonAccountName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.testGcs2(salonAccountName, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerApp(
    salonAccountName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerApp(
        salonAccountName,
        modelName,
        platform,
        deviceUuid,
        osVersion,
        appVersion,
        appType,
        (result) => {
          if (result.error) {
            reject(result);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  updateApp(
    token: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.updateApp(token, modelName, platform, deviceUuid, osVersion, appVersion, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findTempTokenByToken(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findTempTokenByToken(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findMyOwnerCredentialData(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findMyOwnerCredentialData(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  removeLinkMyOwnerCredential(token: string, linkName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.removeLinkMyOwnerCredential(token, linkName, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findPWAReservationAppSalonData(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findPWAReservationAppSalonData(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findMails(token: string, fromIndex: number, toIndex: number, messageType: string, salonId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findMails(token, fromIndex, toIndex, messageType, salonId, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findOwnerCredentialByKey(key: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findOwnerCredentialByKey(key, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findOwnerCredentialByAffiliateNumber(key: string, affiliateNumber: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findOwnerCredentialByAffiliateNumber(key, affiliateNumber, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  updatePushToken(token: string, iosPushToken: string, androidPushToken: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.updatePushToken(token, iosPushToken, androidPushToken, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findNotificationStatus(salonAccountName: string, token: string, isEnterprise: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findNotificationStatus(salonAccountName, token, isEnterprise, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerOrUpdateLineNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerOrUpdateLineNotification(salonAccountName, token, isRegistered, isEnterprise, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerOrUpdateMailNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerOrUpdateMailNotification(salonAccountName, token, isRegistered, isEnterprise, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerPwaCustomerMailAddress(
    salonAccountName: string,
    customerMailAddress: string,
    restorePreviousAddress: boolean,
    token: string,
    isEnterprise: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerPwaCustomerMailAddress(
        salonAccountName,
        customerMailAddress,
        restorePreviousAddress,
        token,
        isEnterprise,
        (result) => {
          if (result.error) {
            reject(result);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  registerOrUpdatePwaNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerOrUpdatePwaNotification(salonAccountName, token, isRegistered, isEnterprise, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerCustomerUniqueNumber(token: string, salonAccountName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerCustomerUniqueNumber(token, salonAccountName, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findSalonList(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findSalonList(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findSalonSetting(salonAccountName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findSalonSetting(salonAccountName, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  executeSendSupportForm(token: string, salonAccountName: string, content: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.executeSendSupportForm(token, salonAccountName, content, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  removeMyCustomerData(token: string, salonId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.removeMyCustomerData(token, salonId, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findLastReservation(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findLastReservation(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  findPwaData(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.findPwaData(token, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  registerEnterpriseApp(
    companyName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.registerEnterpriseApp(
        companyName,
        modelName,
        platform,
        deviceUuid,
        osVersion,
        appVersion,
        appType,
        (result) => {
          if (result.error) {
            reject(result);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  addSalonFromEnterprise(token: string, companyName: string, customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api.addSalonFromEnterprise(token, companyName, customerId, (result) => {
        if (result.error) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }
}
