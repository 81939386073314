import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mail-svg',
  templateUrl: './mail-svg.component.html',
  styleUrls: ['./mail-svg.component.scss']
})
export class MailSvgComponent implements OnInit {
  @Input() isShopDetailNav: boolean = false;
  constructor() {}

  ngOnInit() {}
}
