<div class="dialog">
  <div class="header-controls">
    <div class="buttons align-right"></div>
  </div>
  <div class="container">
    <div class="dialog-title">「通知」設定のお願い</div>
    <div class="dialog-body">
      <div class="section-block quarter-padding bold">
        予約に関するお知らせを、このアプリからのプッシュ通知に変更します。
      </div>
      <div class="section-block quarter-padding description">
        これまではアプリのプッシュ通知の代わりに、LINEに通知されていました。今後はLINEへの通知が停止され、このアプリからのプッシュ通知に変更になります。
      </div>
      <div class="section-block quarter-padding">
        次に表示されるダイアログで「許可」を選択して、予約受付のお知らせやサロンからの連絡など重要な情報を受け取ってください。
      </div>
      <!-- イラスト -->
      <div class="image-container">
        <img src="./../../assets/images/icons/ios_notice_sample_push.svg" />
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <ion-button fill="solid" color="dark-button" class="dialog-button" (click)="next()"> 次へ </ion-button>
  </div>
</div>
