import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-store-list-svg',
  templateUrl: './store-list-svg.component.html',
  styleUrls: ['./store-list-svg.component.scss']
})
export class StoreListSvgComponent implements OnInit {
  @Input() isShopDetailNav: boolean = false;
  constructor() {}

  ngOnInit() {}
}
