import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { HomePage } from './home.page';
import { FooterModule } from './../footer/footer.module';
import { HeaderModule } from './../header/header.module';
import { MobilefooterModule } from './../mobilefooter/mobilefooter.module';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PrivatePageModule } from './../private/private.module';
import { HomeFooterModule } from './../homefooter/homefooter.module';
import { GallerymodalModule } from './../gallerymodal/gallerymodal.module';
import { EmergencyNewsPageModule } from './../emergencynews/emergencynews.module';
import { PagedataService } from '../services/pagedata.service';
import { MobilefooterbuttonsModule } from './../mobilefooterbuttons/mobilefooterbuttons.module';
import { PipesModule } from './../pipe/pipe.module';
import { BlockComponentModule } from './../block/block.module';

const routes: Routes = [
  {
    path: '',
    component: HomePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    HeaderModule,
    MobilefooterModule,
    RouterModule.forChild(routes),
    LazyLoadImageModule,
    PrivatePageModule,
    HomeFooterModule,
    GallerymodalModule,
    EmergencyNewsPageModule,
    MobilefooterbuttonsModule,
    PipesModule,
    BlockComponentModule
  ],
  declarations: [HomePage],
  exports: [HomePage]
})
export class HomePageModule {}
