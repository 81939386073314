import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';
import { ShopdetailmodalComponent } from './shopdetailmodal.component';
import { FormsModule } from '@angular/forms';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PrivatePageModule } from '../private/private.module';
import { HomeFooterModule } from '../homefooter/homefooter.module';
import { HeaderModule } from '../header/header.module';
import { MobilefooterModule } from '../mobilefooter/mobilefooter.module';
import { GallerymodalModule } from '../gallerymodal/gallerymodal.module';
import { EmergencyNewsPageModule } from '../emergencynews/emergencynews.module';

import { HomePageModule } from '../home/home.module';
import { MenuPageModule } from '../menu/menu.module';
import { ShopPageModule } from '../shop/shop.module';
import { StaffPageModule } from '../staff/staff.module';
import { StaffdetailPageModule } from '../staffdetail/staffdetail.module';
import { CampaignPageModule } from '../campaign/campaign.module';
import { NewsPageModule } from '../news/news.module';
import { NewsdetailPageModule } from '../newsdetail/newsdetail.module';
import { GalleryPageModule } from '../gallery/gallery.module';
import { FreePageModule } from '../free/free.module';

@NgModule({
  declarations: [ShopdetailmodalComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    LazyLoadImageModule,
    PrivatePageModule,
    HomeFooterModule,
    GallerymodalModule,
    EmergencyNewsPageModule,
    HeaderModule,
    MobilefooterModule,
    HomePageModule,
    MenuPageModule,
    ShopPageModule,
    StaffPageModule,
    StaffdetailPageModule,
    CampaignPageModule,
    NewsPageModule,
    NewsdetailPageModule,
    GalleryPageModule,
    FreePageModule
  ],
  exports: [ShopdetailmodalComponent]
})
export class ShopdetailmodalModule {}
