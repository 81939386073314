import { Injectable } from '@angular/core';

@Injectable()
export abstract class ReservationServiceProvider {
  abstract testGcs(): Promise<any>;

  abstract testGcs2(salonAccountName: string): Promise<any>;

  abstract registerApp(
    salonAccountName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string
  ): Promise<any>;

  abstract updateApp(
    token: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string
  ): Promise<any>;

  abstract findTempTokenByToken(token: string): Promise<any>;

  abstract findMyOwnerCredentialData(token: string): Promise<any>;

  abstract removeLinkMyOwnerCredential(token: string, linkName: string): Promise<any>;

  abstract findPWAReservationAppSalonData(token: string): Promise<any>;

  abstract findMails(
    token: string,
    fromIndex: number,
    toIndex: number,
    messageType: string,
    salonId: number
  ): Promise<any>;

  abstract findOwnerCredentialByKey(key: string): Promise<any>;

  abstract findOwnerCredentialByAffiliateNumber(key: string, affiliateNumber: string): Promise<any>;

  abstract updatePushToken(token: string, iosPushToken: string, androidPushToken: string): Promise<any>;

  abstract findNotificationStatus(salonAccountName: string, token: string, isEnterprise: boolean): Promise<any>;

  abstract registerOrUpdateLineNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any>;

  abstract registerOrUpdateMailNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any>;

  abstract registerPwaCustomerMailAddress(
    salonAccountName: string,
    customerMailAddress: string,
    restorePreviousAddress: boolean,
    token: string,
    isEnterprise: boolean
  ): Promise<any>;

  abstract registerOrUpdatePwaNotification(
    salonAccountName: string,
    token: string,
    isRegistered: boolean,
    isEnterprise: boolean
  ): Promise<any>;

  abstract registerCustomerUniqueNumber(token: string, salonAccountName: string): Promise<any>;

  abstract findSalonList(token: string): Promise<any>;

  abstract findSalonSetting(salonAccountName: string): Promise<any>;

  abstract executeSendSupportForm(token: string, salonAccountName: string, content: string): Promise<any>;

  abstract removeMyCustomerData(token: string, salonId: number): Promise<any>;

  abstract findLastReservation(token: string): Promise<any>;

  abstract findPwaData(token: string): Promise<any>;

  abstract registerEnterpriseApp(
    companyName: string,
    modelName: string,
    platform: string,
    deviceUuid: string,
    osVersion: string,
    appVersion: string,
    appType: string
  ): Promise<any>;

  abstract addSalonFromEnterprise(token: string, companyName: string, customerId: number): Promise<any>;
}
