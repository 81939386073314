import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { HomeFooterComponent } from './homefooter.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [HomeFooterComponent],
  imports: [CommonModule, RouterModule, IonicModule, LazyLoadImageModule],
  exports: [HomeFooterComponent]
})
export class HomeFooterModule {}
