import { Injectable } from '@angular/core';

// イベント発火のための Subject を import
import { Subject } from 'rxjs';

@Injectable()
export class NoticeCloseA2hs {
  private isClose: boolean = false;
  /**
   * データの変更を通知するためのオブジェクト
   *
   * @private
   * @memberof NoticeCloseA2hs
   */
  private sharedDataSource = new Subject<boolean>();

  /**
   * Subscribe するためのプロパティ
   * `- コンポーネント間で共有するためのプロパティ
   *
   * @memberof NoticeCloseA2hs
   */
  public sharedDataSource$ = this.sharedDataSource.asObservable();

  /**
   * コンストラクタ. NoticeCloseA2hs のインスタンスを生成する
   *
   * @memberof NoticeCloseA2hs
   */
  constructor() {}

  /**
   * データの更新イベント
   *
   * @param {isClose} updated 更新データ
   * @memberof NoticeCloseA2hs
   */
  public onNotifySharedDataChanged(isClose: boolean) {
    if (isClose) {
      this.isClose = true;
    }
    this.sharedDataSource.next(isClose);
  }

  public getIsClose(): boolean {
    return this.isClose;
  }
}
