<ion-content
  *ngIf="(pageData.isDisplay && isNews && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="news_detail_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
  [class.__modalStyle__]="isShopDetailNav"
>
  <main class="main mainNewsDetail content_top rmMbLastContentsGroup">
    <!-- ニュースページ　見出し -->
    <section class="newsDetailHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="clickLink( breadcrumbsData.url )"
              [class.__modalStyle__]="isShopDetailNav"
              >{{ breadcrumbsData.title }}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span href="javascript:void(0);" class="newsDetailHeading__backLink" (click)="navigationController.goBack()"
          ><</span
        >
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="newsDetailHeading_container secContainer">
        <h2 class="newsDetailHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">
          {{pageData.title}}
        </h2>
        <h1
          class="newsDetailHeading__title jpfont"
          [innerHTML]="pageData.news.title"
          [class.__modalStyle__]="isShopDetailNav"
        ></h1>
      </div>
      <div class="newsDetailHeading_container secContainer">
        <p class="newsDetailHeading__date" [class.__modalStyle__]="isShopDetailNav">{{pageData.news.releaseDate}}</p>
      </div>
      <!-- /.newsDetailHeading_container -->
      <div
        class="newsDetailBlock blockArea"
        *ngIf="blockData['newsdetail' + pageData.news.id + '__dateBlock'] && blockData['newsdetail' + pageData.news.id + '__dateBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['newsdetail' + pageData.news.id + '__dateBlock']"
          [position]="'newsdetail' + pageData.news.id + '__dateBlock'"
          #appBlock
        ></app-block>
      </div>
    </section>
    <!-- /.newsDetailHeading -->

    <!-- ニュースコンテンツ -->
    <section class="newsDetailContent sec">
      <div class="newsDetailContent_imageContainer secContainer">
        <!-- ニュースアイキャッチ画像 -->
        <div
          *ngIf="(pageData.news.image || pageData.news.imageIframe ) && pageData.news.isDisplayImage"
          class="newsDetailContent__eyecatch secContent"
        >
          <div class="newsDetailContent__eyecatch__inner">
            <picture *ngIf="pageData.news.imageDisplayType != 4">
              <source *ngIf="pageData.news.webpImage != null" type="image/webp" [srcset]="pageData.news.webpImage" />
              <img
                class="newsDetailContent__eyecatch__inner__img"
                [src]="pageData.news.image"
                alt="{{ pageData.news.imageAlt }}"
                [class.width90]="pageData.news.imageDisplayType == 2"
                [class.width50]="pageData.news.imageDisplayType == 3"
              />
            </picture>
            <div
              class="newsDetailContent__eyecatch__video"
              *ngIf="pageData.news.imageDisplayType == 4"
              [innerHTML]="pageData.news.imageHtml"
              [ngClass]="['iframe_newsDetail_' + pageData.id]"
              #videoIframeContent
            ></div>
          </div>
        </div>
        <!-- /.newsDetailContent__eyecatch -->
      </div>
      <!-- /.newsDetailContent_container -->
      <div
        class="newsDetailBlock blockArea"
        *ngIf="blockData['newsdetail' + pageData.news.id + '__visualBlock'] && blockData['newsdetail' + pageData.news.id + '__visualBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['newsdetail' + pageData.news.id + '__visualBlock']"
          [position]="'newsdetail' + pageData.news.id + '__visualBlock'"
          #appBlock
        ></app-block>
      </div>
    </section>
    <!-- /.newsDetailContent -->

    <!-- ニュースコンテンツ -->
    <section
      class="newsDetailContent sec"
      *ngIf="(blockData['newsdetail' + pageData.news.id + '__bodyBlock'] && blockData['newsdetail' + pageData.news.id + '__bodyBlock'].length > 0) || pageData.news.body"
    >
      <div class="newsDetailContent_container secContainer" *ngIf="pageData.news.body">
        <!-- ニュース詳細 -->
        <div class="newsDetailContent__detail" [class.__modalStyle__]="isShopDetailNav">
          <p [innerHTML]="pageData.news.body | htmlSanitize"></p>
        </div>
      </div>
      <!-- /.newsDetailContent_container -->
      <div
        class="newsDetailBlock blockArea"
        *ngIf="blockData['newsdetail' + pageData.news.id + '__bodyBlock'] && blockData['newsdetail' + pageData.news.id + '__bodyBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['newsdetail' + pageData.news.id + '__bodyBlock']"
          [position]="'newsdetail' + pageData.news.id + '__bodyBlock'"
          #appBlock
        ></app-block>
      </div>
    </section>
    <!-- /.newsDetailContent -->
  </main>
  <!-- /.mainStaff -->

  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
