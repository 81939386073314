// FCMの情報（検証用）
export const firebaseConfig = {
  test: {
    production: false,
    firebase: {
      apiKey: 'AIzaSyCsJJWVUoa6Q04wQ8ndymwyx9vbyUkO8jM',
      authDomain: 'cspwa-push-test.firebaseapp.com',
      projectId: 'cspwa-push-test',
      storageBucket: 'cspwa-push-test.appspot.com',
      messagingSenderId: '263277513742',
      appId: '1:263277513742:web:1979023e49092cb639c05f',
      vapidKey: 'BLNxRYWO21LkALoR-VgNaKiH96xyZtRSgrUNSLqAoZFoyLqaxhu8ynFQWwnQdYfi0EBP9vuiVG86DEd_8n_OykQ'
    }
  },
  production: {
    production: true,
    firebase: {
      apiKey: 'AIzaSyChlUG2Vr0MHXRZrlXKBoE-WPTqlfrXoZQ',
      authDomain: 'cspwa-push-production-35166.firebaseapp.com',
      projectId: 'cspwa-push-production-35166',
      storageBucket: 'cspwa-push-production-35166.appspot.com',
      messagingSenderId: '489388714213',
      appId: '1:489388714213:web:e1bd13e8384ae321ce0c0c',
      measurementId: 'G-9PHRYV17ZK',
      vapidKey: 'BPPhrlPR35vg-E-17K_6JEcu-En3agb-BF37RhFZ9ciJppk9qPfejQDTxWkeNOYJ9VvAopt_1twqZYuEf0qOgW4'
    }
  }
};
