<!-- フローティングボタン -->
<div class="floatingButtonContainer">
  <!-- トップに戻る -->
  <div id="scrollTopForMobileContainer" *ngIf="getIsShowScrollTopForMobile()" (click)="scrollToTop()">
    <div class="scrollTopForMobile"></div>
  </div>
  <!-- 予約ボタン -->
  <!-- <div class="floatingReservationForMobile jpfont">
    予約
  </div>  -->
</div>
<!-- /.floatingButtonContainer-->
