import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule) },
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then((m) => m.MenuPageModule) },
  { path: 'staff', loadChildren: () => import('./staff/staff.module').then((m) => m.StaffPageModule) },
  {
    path: 'staffdetail',
    loadChildren: () => import('./staffdetail/staffdetail.module').then((m) => m.StaffdetailPageModule)
  },
  { path: 'shop', loadChildren: () => import('./shop/shop.module').then((m) => m.ShopPageModule) },
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then((m) => m.GalleryPageModule) },
  { path: 'news', loadChildren: () => import('./news/news.module').then((m) => m.NewsPageModule) },
  {
    path: 'newsdetail',
    loadChildren: () => import('./newsdetail/newsdetail.module').then((m) => m.NewsdetailPageModule)
  },
  { path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then((m) => m.CampaignPageModule) },
  { path: '**', loadChildren: () => import('./free/free.module').then((m) => m.FreePageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
