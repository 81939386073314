import { Component, OnInit, Input } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';
import { ReservationServiceProvider } from '../services/ReservationServiceProvider';
import { RequestParamsService } from '../services/requestParams.service';
import { PagedataService } from '../services/pagedata.service';
import { SalonData } from '../interfaces/salonData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { initSalonData } from '../initData/initData';

@Component({
  selector: 'app-affiliatenumber',
  templateUrl: './affiliatenumber.component.html',
  styleUrls: ['./affiliatenumber.component.scss']
})
export class AffiliateNumberComponent implements OnInit {
  private affiliateNumber: string = ''; // 認証コード
  public affiliateFirstNumber: string = '';
  public affiliateSecondNumber: string = '';
  public affiliateThirdNumber: string = '';
  public affiliateFourthNumber: string = '';
  @Input() pageName: string;
  public isFailureAuth = false; // 認証コードが異なる
  public isExpiredKey = false; // Key(URL)の有効期限切れ
  public salonData: SalonData = initSalonData;
  public layoutData: LayoutData;

  constructor(
    private modalCtrl: ModalController,
    public rs: ReservationServiceProvider,
    private requestParamsService: RequestParamsService,
    public pds: PagedataService
  ) {}

  ngOnInit() {
    this.load();
  }

  private async load() {
    this.salonData = await this.pds.getSalonData();
    this.layoutData = await this.pds.getLayoutData();
  }

  // モーダルを閉じる
  public async close() {
    this.isFailureAuth = false;
    await this.modalCtrl.dismiss();
  }

  // 決定ボタン
  public async decision() {
    this.affiliateNumber =
      this.affiliateFirstNumber + this.affiliateSecondNumber + this.affiliateThirdNumber + this.affiliateFourthNumber;

    const params = this.requestParamsService.getRequestParams();
    const key = params.key;
    // 認証コードが正しいかチェック
    const result = await this.rs.findOwnerCredentialByAffiliateNumber(key, this.affiliateNumber).catch((error) => {
      return error;
    });

    // 認証コードが正しくない
    if (result.error) {
      // return await this.noMatchNumberAlert();
      this.isFailureAuth = true;
    }

    // 認証コードが正しい
    if (result.message == 'ok') {
      const tempToken: string = result.tempToken;
      const token: string = result.token;
      const data = { token, tempToken };
      await this.modalCtrl.dismiss(data);
    }
  }

  //数字・テンキーで次のinputへ
  public moveFocus(event: KeyboardEvent, nextElement: IonInput, previousElement: IonInput) {
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      // キーボードの0~9
      if (nextElement) {
        nextElement.setFocus();
      }
    } else if (event.keyCode >= 96 && event.keyCode <= 105) {
      // テンキーの0~9
      if (nextElement) {
        nextElement.setFocus();
      }
    } else {
      // 数字以外は不可
      (<HTMLInputElement>event.target).value = '';
    }
  }
}
