import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { PrivatePage } from './private.page';

import { FooterModule } from './../footer/footer.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [PrivatePage],
  imports: [CommonModule, RouterModule, IonicModule, LazyLoadImageModule, FooterModule],
  exports: [PrivatePage]
})
export class PrivatePageModule {}
