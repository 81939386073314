<ion-header id="modalHeader" class="modal-header">
  <ion-toolbar class="modalHeader__toolbar">
    <ion-buttons slot="start">
      <ion-button class="back-btn" [class.show]="isCanBack" (click)="back()">
        <ion-icon class="backBtn" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="close-btn" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="modal-header-title">{{ layoutData.shortName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="modal-body __modalStyle__" [scrollY]="false" [ngClass]="shopDetailModalStyles">
  <app-header
    #appHeader
    id="addBlur"
    [class.blur_active]="blurActiveModalFlg"
    [shopName]="this.shopName"
    [isShopDetailNav]="this.isShopDetailNav"
  ></app-header>
  <app-mobilefooter
    #mobileFooter
    *ngIf="!isShowPrivateMessage"
    class="forShop"
    [blurActiveFlg]="blurActiveModalFlg || blurActiveModalFlgFromHeader"
    [class.blur_active]="blurActiveModalFlg || blurActiveModalFlgFromHeader"
    [shopName]="this.shopName"
    [isShopDetailNav]="this.isShopDetailNav"
    [pageName]="this.pageName"
  ></app-mobilefooter>
  <ion-nav #shopDetailNav (ionNavDidChange)="checkCanGoBack()"></ion-nav>
</ion-content>
